import React from 'react'

export default function TermsAndCondition() {
  return (
    <section className="">
      <div className="container">
        <div className="my-5">
          <div>
            <p className='line-border-bottom text-center'>
              <span className=''>
                <strong className='fs-2'>
                  Amog Office Automation Terms and Conditions and Privacy Policy
                </strong>
              </span>
            </p>
            <p>
              The present policy lays down how the COMPANY collects, uses, discloses, shares and transfers Information of USERs. This Policy would apply to anybody who visits ManageMySite (also known as MMS) Platform or opens an account with the COMPANY on the MMS Platform, as the case may be.
            </p>
            <p>
              Privacy Policy hereinafter defined as “POLICY” is executed through Amog Office Automation Private Limited a COMPANY incorporated and registered under the Companies Act -- hereinafter referred to as “the COMPANY” having its registered address Khasra No 228, Sector 104, Gautam Budh Nagar, Noida, Uttar Pradesh-201301 The " COMPANY" recognizes the need for appropriate protection and management of any information shared with the COMPANY or on ManageMySite Platform hereinafter referred to as " MMS" 'Policy
            </p>
            <p>
              For the purpose of this POLICY, users who use services of MMS Platform for business by creating an account, hereinafter referred to as the USER.
            </p>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                  TERMS AND CONDITIONS
                </strong>
              </span>
            </p>
            <p>
              By using or accessing the MMS platform in any manner or opening an Account, it is acknowledged that the present Policy is accepted by the USER and it further consents that the COMPANY will collect, use, disclose, share and transfer information and personal data including but not limited to sensitive Personal Information. This Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder.
            </p>
            <p>
              However, in case the USER does not intend to share some of the Information as referred herein below for use by the COMPANY as stated, the USER is free to opt-out or not use the MMS platform and/or the Products and Services by asking for deletion of the Account sending a mail to <a href="mailto:help@managemysite.org">help@managemysite.org</a> and in such a case, are not required to give consent as per the present Privacy Policy.
            </p>
            <p>
              In the case in future it is felt that the Information or a part thereof as collected should not be used or should be deleted, consent granted in the present POLICY can be withdrawn, by writing to the COMPANY seeking to withdraw the consent so granted or deleting the Account, as the case may be. However, in case the consent for the COMPANY to use the Information as provided in the present POLICY is withdrawn, the Products and Services cannot be offered to the USER by the COMPANY.
            </p>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                MODIFICATION OF PRIVACY POLICY
                </strong>
              </span>
            </p>
            <ul>
              <li>
                These policies may be updated from time to time in the future. The USER should agree to review our privacy policies regularly by visiting this page. Continued access to or use of the service will mean that the USER agrees to the change.
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                WHEN THIS PRIVACY STATEMENT APPLIES
                </strong>
              </span>
            </p>
            <ul>
              <li>
                Its liability to the USER is limited to those who have downloaded the MMS application and logged in by clicking on the “I Agree/Login” button for Terms and Conditions or has explicitly consented to the policy on any other product/service page offered to the USER
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                HOW IS THE INFORMATION COLLECTED, USED AND STORED
                </strong>
              </span>
            </p>
            <ol type='1'>
              <li className='mt-3'>
                The COMPANY collects, uses, discloses, shares and transfers Information, including but not limited to USER content, other content, communications, information etc. provided by USERs when the USERs access or use the MMS platform for the Products and Services. By use of the MMS platform and/or Products and Services, express consent and permission is granted to the COMPANY for having access to, using, processing, disclosing, sharing and transferring of information in accordance with this Policy and the Terms of Use.
              </li>
              <li className='mt-3'>
                For the purpose of these Terms, the term “Personal Information” shall mean any information provided by the USER and shall include, without limitation USER content, any and all documents, details, information etc. shared with the COMPANY by the USER and the One Time Password (OTP) received by the USER from the government authorities/good and service tax authority for authenticating/verifying the goods and service tax registration process; in furtherance to the Terms of Use.
                <ol type='a'>
                  <li className='mt-3'>
                    For the purpose of these Terms, the term “Non-Personal Information” shall mean INFORMATION that does not specifically identify an individual but includes information from such as the type of internet browser used, mobile device used, computer or mobile device’s unique device ID, Internet Service Provider (ISP) and Internet Protocol (IP) address. The COMPANY may also collect Non-Personal Information that is provided.
                  </li>
                  <li className='mt-3'>
                    Personal and Non-Personal Information together is referred to as “Information”. The COMPANY may collect Information when registration or opening of an Account happens on the MMS platform, when the features or the Products and Services are used, when the USER shares the USER content with the COMPANY and when the USER interacts with the customer care team including the details of the message or chats etc.
                  </li>
                </ol>
              </li>
              <li className='mt-3'>
                The COMPANY may use cookies to monitor usage including, without limitation, to provide useful features to simplify experiences for the USER on the platform, like remembering login ID and certain Personal Information and to deliver relevant content based on preferences, usage patterns and location.
              </li>
              <li className='mt-3'>
                The COMPANY collects information about the USER’s use of the platform and/or Products and Service, such as the features used, any activity carried out, the actions that are taken, the time, frequency and duration of the said activities.
              </li>
              <li className='mt-3'>
                The COMPANY is required to collect information from and about the computers, tablets, phones and other web-connected devices used and the COMPANY then combines this information across different devices that are used.
                <br /><span>Information that the COMPANY obtains from these devices includes:</span>
                <ol type='a'>
                  <li className='mt-3'>
                    Information including but not limited to the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins, name of mobile operator or ISP, language, time zone, mobile phone number, IP address, connection speed and, in some cases, information about other devices that are nearby or on USER network etc.
                  </li>
                  <li className='mt-3'>
                    Information including but not limited to access to beacons and mobile phone masts, access to GPS location, network, camera or photos, data from cookies stored on USER devices, storage space or disk on the device etc.
                  </li>
                  <li className='mt-3'>
                    Location-related information – such as current location to provide, personalized and improved use of the platform and/or Products and Services. Location-related information can be based on things such as precise device location, IP addresses etc.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                COLLECTION OF DEVICE LOCATION AND DEVICE INFORMATION
                </strong>
              </span>
            </p>
            <ol type='1'>
              <li className='mt-3'>
                The COMPANY collects and monitor the information about the location of your device to provide serviceability challans, purchase order and voucher thus enhance the authenticity of the image data.
              </li>
              <li className='mt-3'>
                Information the App collects, and its usage depends on how you manage your privacy controls on your device. When you install the App, we store the information we collect with unique identifiers tied to the device you are using. We collect information from the device when you download and install the App and explicitly seek permissions from You to get the required information from the device. The information we collect from your device includes the hardware model, build model, RAM, storage; to ensure better serviceability
              </li>


            </ol>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                COLLECTION OF IMAGES
                </strong>
              </span>
            </p>
            <ul>
              <li>
                The images uploaded by USERs in MMS App are stored on our servers so they can be accessed from multiple devices. We do this to enhance your USER experience and make it seamless.
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                STORAGE
                </strong>
              </span>
            </p>
            <ul>
              <li>
                The COMPANY requires storage permission so that your images and other relevant documents can be securely downloaded and saved on your phone. You can then easily upload the correct documents related to Purchase order, Challans or Vouchers. This ensures that you are provided with a seamless experience while using the application.
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                CAMERA
                </strong>
              </span>
            </p>
            <ul>
              <li>
                The COMPANY require the camera information permission to provide you with an easy/smooth experience and to enable you to click photos of related to your site and other related documents and upload the same on the App during your application journey.
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                COLLECTION OF OTHER NON-PERSONAL INFORMATION
                </strong>
              </span>
            </p>
            <ul>
              <li className='mt-3'>
                The COMPANY automatically tracks certain information about you based upon your behavior on our Platform. We use this information to do internal research on our user’s demographics, interests, and behavior to better understand, protect and serve our user’s and improve our services. This information is compiled and analyzed on an aggregated basis.
              </li>
              <li className='mt-3'>
                The COMPANY also collect your Internet Protocol (IP) address and the URL used by you to connect your computer to the internet, etc. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.
              </li>
              <li className='mt-3'>
                Cookie based data - Cookies are small data files that a Website stores on Your computer. We will use cookies on our Website similar to other lending websites/apps and online marketplace websites/apps. Use of this information helps Us identify You in order to make our Website more user friendly. Most browsers will permit You to decline cookies but if You choose to do this it might affect service on some parts of Our Website. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.
                <br />
                If you send us personal correspondence, such as emails or letters, or if other USERs or third parties send us correspondence about your activities or postings on the Website, we collect such information into a file specific to you.
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                HOW MMS USES USER DATA
                </strong>
              </span>
            </p>
            The COMPANY may use the Information for:
            <ol type='1'>
              <li className='mt-3'>
                Providing access to the MMS platform and/or use of the Products and Services.
              </li>
              <li className='mt-3'>
                Manage Account with the COMPANY.
              </li>
              <li className='mt-3'>
                Authentication by using One Time Password received from the for the Purpose including but not limited to government authorities/good and service tax authority for authenticating/verifying the goods and service tax registration process, Bureau check or any other product/service availed by the USER from time to time on MMS.
              </li>
              <li className='mt-3'>
                Conduct general survey or analysis of Accounts or otherwise.
              </li>
              <li className='mt-3'>
                Develop, test and improve the platform and/or Products and Services, testing and troubleshooting new products and features, removing any glitches including technical problems, improving the experience of navigating and carrying out transactions and improving the services and content, Products and Service on the platform.
              </li>
            </ol>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                SHARING AND DISCLOSURE OF PERSONAL INFORMATION
                </strong>
              </span>
            </p>
            The COMPANY may disclose or share Personal Information including but not limited to USER Content in the following ways:
            <ol type='1'>
              <li className='mt-3'>
                Disclosure or sharing with government authorities including but not limited to the Goods and Service Tax Authority, MSME Authority, FSSAI Authority. Information including Personal Information may be shared with and disclosed to government authorities including but not limited to the Goods and Service Tax Authority, MSME Authority, FSSAI Authority, in order to enable providing the Products and Services.
              </li>
              <li className='mt-3'>
                Disclosure to Government authorities or as required by law information may be shared with Government and/or statutory authorities or under court orders or other legal processes; to establish or exercise legal rights; to defend against legal claims; or as otherwise required by law. The COMPANY warrants that such information will be disclosed only in accordance with applicable directions, instructions, laws and regulations.
              </li>
              <li className='mt-3'>
                Disclosure to third parties the COMPANY may provide, disclose and share information including Personal Information that is collected to third-party service providers, database service providers, backup and disaster recovery service providers, email service providers and also make it accessible to technology partners, subsidiaries and affiliates, including outside India, to help ensure availability of platform and providing the Products and Services
              </li>
              <li className='mt-3'>
                Disclosure to prospective Acquirers The COMPANY may disclose information including Personal Information to an acquirer, assignee or other successor entity in connection with a sale, merger, or reorganization of all or substantially all of the equity or business of the COMPANY.
              </li>
            </ol>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                PROTECTION OF INFORMATION
                </strong>
              </span>
            </p>
            <ul>
              <li className='mt-3'>
                The COMPANY, on a best effort basis, shall attempt to take adequate measures to protect the security of Personal Information and to ensure that the choices for its intended use are honored. The COMPANY also takes adequate precautions, on a best effort basis, to protect data from loss, misuse, unauthorized access or disclosure, alteration or destruction.
              </li>
              <li className='mt-3'>
                The COMPANY uses industry standards of physical, technical and administrative security measures to keep Information, on best effort basis, secure although is unequivocally understood and agreed that the same cannot be foolproof since no security system is impassable and the COMPANY does not guarantee that the Information may be absolutely free from invasion.
              </li>
              <li className='mt-3'>
                lease note that e-mails, messaging and means of communication with the COMPANY may not be encrypted, and therefore the COMPANY advises not to communicate any confidential information through these means.
              </li>
              <li className='mt-3'>
                The COMPANY is not responsible for any breach of security or for any actions or inactions of any USERs or other third parties including but not limited to government authorities that receive Information.
              </li>
              <li className='mt-3'>
                The USER is solely responsible for maintaining the confidentiality of its username and password. To ensure the safety of Personal Information, it is advised that the username and password should not be shared with anyone. The COMPANY would not be liable or responsible for any breach of this covenant for any reason.
              </li>
              <li className='mt-3'>
                The COMPANY would retain Personal Information only as long as the entity or USER to whom the said Personal Information belongs has an active Account or as permitted/required by applicable law. Generally, Personal Information, upon deletion of a related Account, is also deleted unless the same is required to comply with any legal requirements, fraud prevention or assistance in any enquiry, for resolving disputes, to protect interests of the COMPANY, to continue to develop and improve the Products and Services
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                DATA OWNERSHIP
                </strong>
              </span>
            </p>
            <ul>
              <li className='mt-3'>
              The USER owns the content created or stored by his/her as we respect his/her right to ownership of content created or stored by them. The USERs use of the Services does grant MMS the license to use, reproduce, adapt, modify, publish or distribute the content created by them or stored in the user’s account for MMS internal purpose. MMS tries keeping USERs data safe but holds zero responsibility in case of data leaks. MMS offers users to download their data up to 30 days post completion of the contract and retains the right to permanently delete user’s data after this time frame to save cost. 
              </li>
            </ul>
          </div>
          <div>
            <p className='line-border-bottom mt-2'>
              <span >
                <strong className='fs-4'>
                GRIEVANCE REDRESSAL MECHANISM
                </strong>
              </span>
            </p>
            <ul>
              <li className='mt-3'>
                In accordance with the Information Technology Act, 2000 and Rules made thereunder, the contact details for raising grievances or obtaining any further information or enquiry, if any are provided below:
              </li>
              <li className='mt-3'>
                <span style={{ color: 'blue' }}>Address:</span>  Khasra No 228, Sector 104, Gautam Budh Nagar, Noida, Uttar Pradesh-201301.
              </li>
              <li className='mt-3'>
                <span style={{ color: 'blue' }}>E-mail:</span> escalations@managemysite.org
              </li>
              <li className='mt-3'>
                <span style={{ color: 'blue' }}>Contact number:</span> +91-9953417299
              </li>
            </ul>
          </div>
        </div>
      </div>
 </section>
  )
}
