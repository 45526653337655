import React from 'react'

export default function Privacy() {
  return (
   <section className="">
   <div className="container">
     <div className="my-5">
       <div>
         <p className='line-border-bottom text-center'>
           <span className=''>
             <strong className='fs-2'>
             Amog Office Automation Private Limited Privacy Policy
             </strong>
           </span>
         </p>
         <p>
         The terms used in the Privacy Policy (“Policy”) have the meaning as defined in the Terms of Use and is to be read together with the Terms of Use. The present policy lays down how the Company collects, uses, discloses, shares and transfers Information of Users.
         </p>
         <p>
         This privacy statement provides a more comprehensive explanation of the privacy practices of Amog Office Automation Private Limited, the company behind the app ManageMySite (referred as MMS). This Policy would apply to anybody (referred as USER) who visits ManageMySite (referred as MMS for this policy) Platform or opens an account with the COMPNAY on the MMS Platform, as the case may be. We are dedicated to safeguarding your privacy. 
         </p>
         <p>
          <ul>
            <li>
            <strong>App Name:</strong>  ManageMySite (Referred as "MMS" )
            </li>
            <li>
            <strong>Developer Name:</strong> ConstructionSiteMgmt
            </li>
          </ul>
         </p>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             GENERAL
             </strong>
           </span>
         </p>
         <ul><li>
         Review these Terms of Service ("Agreement") thoroughly. This Agreement is a legal agreement between the USER  and MMS. By accepting electronically (for example, clicking “I Agree”), installing, accessing or using the Services, User agrees to these terms. If the User does not agree to this Agreement, then he/she may not use the Services. This License Agreement shall be governed by and construed in accordance with the laws of the INDIA.
          </li></ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             MODIFICATION OF TERMS & CONDITIONS
             </strong>
           </span>
         </p>
         <ul>
           <li>
           These terms may be updated from time to time in the future. The User should agree to review our Terms and Conditions regularly by visiting this
           page. Continued access to or use of the service will mean that the User agrees to the change.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             WHEN THIS PRIVACY STATEMENT APPLIES
             </strong>
           </span>
         </p>
         <ul>
           <li>
           Its liability to the User is limited to those who have downloaded the MMS application by clicking on the “I Agree/Login” button for Terms and Conditions.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             TERMS AND DESCRIPTION OF SERVICES
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           The COMPANY provides an array of services for your site(s) including Purchase Management, Vouchers, Inventory Management, Daily Cashbook, Reporting, Billing Management and so on to serve as a complete business management software ("Service" or "Services"). The User may use the Services for his/her personal and business use or for internal business purpose in the organization that he/she represent. 
           </li>
           <li className='mt-3'>
           The USER may download the application from the Official website/ Google Play Store using the Internet. A USER can create and add content to his/her account staying. The user is required to give permissions to MMS app on mobile regarding usage of camera and location. The User is responsible for obtaining access to the Internet and the device necessary to use the Services. (Side Note: MMS tries its best to make Businesses compliant to taxation and other laws as much as possible. But, it is solely the User’s responsibility to see that he/she remains compliant with GST and other laws levied by Government. MMS is not in any case responsible for the violation of the compliance caused by software)
           </li>
           <li className='mt-3'>
           For the software subscription services purchased on MMS, the validity of the license period shall commence on or before 60 days since the date of purchase. MMS reserves all rights to increase or reduce this grace period
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             BUSINESS DATA AND PRIVACY
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           The USER alone is responsible for maintaining the confidentiality of his/her username, password and other sensitive information. He/She is responsible for all activities that occur in his/her user account and he/she agrees to inform us immediately of any unauthorized use of their user account by email to help@managemysite.org.
           </li>
           <li className='mt-3'>
           The COMPANY is not responsible for any loss or damage to his/her or to any third party incurred as a result of any unauthorized access and/or use of his/her user account, or otherwise. We are neither responsible for any kind of data loss as performing necessary backups on data is solely the User’s responsibility. MMS is not responsible for any kind of data discrepancy or any type of loss occurred due to data discrepancy/software issue.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             DATA OWNERSHIP
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           The USER owns the content created or stored by his/her as we respect his/her right to ownership of content created or stored by them. The USERs use of the Services does grant MMS the license to use, reproduce, adapt, modify, publish or distribute the content created by them or stored in the user’s account for MMS internal purpose. 
           </li>
           <li className='mt-3'>
           MMS tries keeping Users data safe but holds zero responsibility in case of data leaks. MMS offers users to download their data up to 30 days post completion of the contract and retains the right to permanently delete user’s data after this time frame to save cost. 
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             PERMISSIONS
             </strong>
           </span>
         </p>
         <ul>
           <li>
           <span style={{color:'blue'}}>Camera:</span>  To take the picture of the truck, related document etc to be attached with transactions.
           </li>
           <li>
           <span style={{color:'blue'}}>Storage:</span>  This app collects metadata of files on your device like file names, types, created times, last updated times for uploading files from device to MMS app.
           </li>
           <li>
           <span style={{color:'blue'}}>Location: </span> This app collects location details to verify your location & current address, to ensure serviceability and to identify unusual activity to prevent against any fraud, we do not collect location when app is in background.
           </li>
           <li>
           <span style={{color:'blue'}}>Device and Personal info: </span> This app collects and monitor specific information about users' Phone Number and your device including your hardware model, operating system and version for usage profiling
           </li>
         </ul>
       </div>
       <div>
        <h3>Permissions Usage:</h3>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             COLLECTION OF DEVICE LOCATION, DEVICE INFORMATION AND PERSONAL INFORMATION
             </strong>
           </span>
         </p>
         <ul>
           <li>
           We collect and monitor the information about the location of your device to tag image locations for improving the reliability of the images captured on app. This improves the authenticity of images at site. 
           </li>
           <li>
           When you install the App, we store the device information such as hardware model, operating system and version for better serviceability to users 
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             COLLECTION OF IMAGES
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           The images uploaded by users in MMS App are stored on our servers so they can be accessed from multiple devices. We do this to enhance your user experience and make it seamless.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             STORAGE
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           We require storage permission so that your images and other relevant documents can be securely downloaded and saved on your phone. You can then easily upload the correct documents related to Purchase order, Challans or Vouchers. This ensures that you are provided with a seamless experience while using the application.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             CAMERA
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           We require the camera information permission to provide you with an easy/smooth experience and to enable you to click photos of related to your site and other related documents and upload the same on the App during your application journey.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             COLLECTION OF OTHER NON-PERSONAL INFORMATION
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           We automatically track certain information about you based upon your behavior on our Platform. We use this information to do internal research on our users’ demographics, interests, and behavior to better understand, protect and serve our users and improve our services. This information is compiled and analyzed on an aggregated basis. We also collect your Internet Protocol (IP) address and the URL used by you to connect your computer to the internet, etc. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.
           </li>
           <li className='mt-3'>
           Cookies are small data files that a Website stores on Your computer. We will use cookies on our Website similar to other lending websites/apps and online marketplace websites/apps. Use of this information helps Us identify You in order to make our Website more user friendly. Most browsers will permit You to decline cookies but if You choose to do this it might affect service on some parts of Our Website. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.
           </li>
           <li className='mt-3'>
           If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we collect such information into a file specific to you.
           </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             DATA SECURITY
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           At MMS, we understand the paramount importance of safeguarding your personal and business information. Our dedication to data security is unwavering, and we employ a comprehensive approach to protect your data against unauthorized access, misuse, or loss.
           </li>
           <li className='mt-3'>
             <strong>Industry-Standard Safeguards:</strong> We leverage industry-standard physical, technical, and administrative measures to ensure the security of your information. Our systems are fortified with robust firewalls, advanced encryption methods, and continuous monitoring to thwart potential security breaches. Our team stays abreast of the latest security trends and technologies, ensuring your data is protected by the best defenses available.
           </li>
           <li className='mt-3'>
             <strong>Continuous Improvement:</strong>  Recognizing that no security system is impenetrable, we are committed to continually enhancing our security measures. Our efforts are guided by a ‘best effort basis’ principle, focusing on continuous improvement and adaptation to evolving threats.
           </li>
           <li className='mt-3'>
             <strong>User Responsibility:</strong>   We emphasize the importance of your role in maintaining the security of your account. It’s crucial to keep your username and password confidential and to exercise caution in their usage. We count on your partnership to uphold this aspect of security.
           </li>
           <li className='mt-3'>
             <strong>Communication Safety:</strong>  Please be aware that standard email and messaging communication with MMS may not be encrypted. We advise against sharing confidential information through these channels to avoid potential risks.
           </li>
           <li className='mt-3'>
             <strong>Data Retention & Deletion Policy:</strong>  Your personal information is retained only as long as necessary. Once your account is no longer active, or as per legal requirements, we responsibly delete your personal data, barring any requirements for legal compliance, fraud prevention, or other critical business needs. Your events data is stored for 30 days, account details are stored forever unless the user requests for deletion of data or account, usage logs are maintained for one month, while transactional logs are maintained for one month. Users can request deletion by sending a mail to help@managemysite.org.
           </li>
           <li className='mt-3'>
             <strong>Your Trust, Our Priority:</strong>   At MMS, your trust is our top priority. While we acknowledge that absolute security is an ongoing challenge, our commitment to protecting your data remains steadfast. We are constantly evolving to meet and exceed industry standards, ensuring that your information is secure with us.
           </li>
           <li className='mt-3'>
              <i>For any concerns or queries regarding our data security practices, please feel free to contact us at help@managemysite.org </i>
           </li>

         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             APPROVAL OF ALERTS, MESSAGES AND PROMOTIONAL CAMPAIGN
             </strong>
           </span>
         </p>
         <ul>
              <li className='mt-3'>
                In addition to the General Conditions of MMS service a User agrees to be bound by the following terms and conditions:
                <ol type='i'>
                  <li>The User agrees to receive promotional, transactional and commercial communications from MMS through calls, email, SMS or push notifications.</li>
                  <li>Under certain conditions, promotional messages are transmitted to the users and that MMS shall not bear any further obligation in respect of such promotional messages.</li>
                </ol>
              </li>
         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             REFUND, CANCELLATION, AND RENEWAL POLICIES:
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
             <strong>Manual Renewal Process:</strong>  Our system does not automatically renew licenses. For continued service, customers are required to manually renew their license at the end of their validity.
           </li>
              <li className='mt-3'>
                <strong>Refund:</strong>  Users can ask for subscription refund by sending a mail to <a href="mailto:help@managemysite.org">help@managemysite.org</a>
              </li>
           <li className='mt-3'>
             <strong>Cancellation:</strong> Users can request cancellation of the subscription at any point by sending a mail to  <a href="mailto:help@managemysite.org">help@managemysite.org</a> from authorized mail id
           </li>

         </ul>
       </div>
       <div>
         <p className='line-border-bottom mt-2'>
           <span >
             <strong className='fs-4'>
             END OF TERMS OF SERVICE
             </strong>
           </span>
         </p>
         <ul>
           <li className='mt-3'>
           If you have any questions or concerns regarding this Agreement, please contact us at help@managemysite.org
           </li>
         </ul>
       </div>
     </div>
   </div>
</section>
  )
}
