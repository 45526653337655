import React from 'react'

function Logo(props) {
  const {width, height, logoUrl} = props;
  const style = {
    width,
    height
  }
  return (
    <div className='logo'><img src={logoUrl} alt="log" style={{...style}} /></div>
  )
}

export default Logo