import { useFormik } from 'formik';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { SignInInitaialValue, SignInValidationSchema } from '../../utils/Validation';
import { auth } from "../../utils/firebase";
import PhoneInput from "react-phone-input-2";
import Spinwheel from '../../components/common/Spinwheel';
import { isExistApi } from '../../utils/ApiEndpointFunctions';
import Logo from '../../components/common/Logo';
function Login() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  function onCaptchVerify() {

    if (window && !window.recaptchaVerifier) {

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {

          },
          'expired-callback': () => {
            toast.error("otp otp expired")
          }
        }
      );
    }
  }

  const handleSubmit = async (values) => {
    
    try {
      setLoading(true);
      onCaptchVerify();
      const appVerifier = window.recaptchaVerifier;

      const formatPh = "+" + values.phoneNumber;

      const res = await isExistApi({ phone: formatPh });

      if (res.status === 200) {
        signInWithPhoneNumber(auth, formatPh, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setLoading(false);
            navigate("/otp-verification", { state: { phoneNumber: formatPh } })
            toast.success("OTP sended successfully!");
          })
          .catch((error) => {
            setLoading(false);
            let errorMsg = { ...error }
            if (errorMsg.code === 'auth/invalid-phone-number') {
              toast.error('Invalid Phone Number!!')
            } else if (errorMsg.code === "auth/too-many-requests") {
              toast.error('Too Many Attempts, Try Later!')
            } else {
              toast.error('Something Went Wrong!!')
            }
          });
      }
    } catch (error) {
      console.log(error)
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error(error?.message);
      setLoading(false);
    }
  }


  const formik = useFormik({
    initialValues: SignInInitaialValue,
    onSubmit: handleSubmit,
    validationSchema: SignInValidationSchema
  })

  return (
    <section className="login">
      <div className="container">
        <div className="centralize">
          <div className="login-box">
            <Logo width="100%" logoUrl="/Group.png" />
            <form className="mt-4 " onSubmit={formik.handleSubmit}>
              <label htmlFor="">Phone number</label>
              <PhoneInput country={"in"} countryCodeEditable={false} value={formik.values?.phoneNumber} onChange={(e) => formik.setFieldValue("phoneNumber", e)} onEnterKeyPress={() => {formik.handleSubmit()}} placeholder="Enter registered phone number" />
              {/* <input type="phone" className="form-control" placeholder="Enter phone number" name='phoneNumber' value={formik.values?.phoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} /> */}
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (<div className='text-danger'>{formik.errors.phoneNumber}</div>) : null}
              <button type='submit' disabled={loading} className="login-btn text-center mt-4">
                {loading ? <Spinwheel sizeclassName="spin-small" /> : " Send OTP"}
              </button>
            </form>
            <p className="mt-4">If your phone number is not registered yet with your company account, please contact your administrator team.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login