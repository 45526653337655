import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { getvendorsApi,getSiteItemApi } from '../../utils/ApiEndpointFunctions';

function FilterReport(props) {
  const { modalId, formik, clickHandler } = props
  const [vendors, setVendors] = useState()
  const [items, setItems] = useState()

  const getVendors = async () => {
    try {
      const res = await getvendorsApi()
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        setVendors(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getItems = async () => {
    try {
      const res = await getSiteItemApi()
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.map(ele => ({ label: ele?._id, value: ele?._id }))
        setItems(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendors()
    getItems()
  }, [])

  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };
  
  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Filter</h5>
                </div>
                <form className="my-fx">
                <div className="ed-box">
                    <label htmlFor="to" className="form-label">Date <span className='req-error'>*</span></label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className={`form-control cursor-pointer ${((formik?.touched?.to && formik?.errors?.to)||(formik?.touched?.from && formik?.errors?.from)) && "border-danger"}`}
                      startDate={ formik.values?.to ? formik.values?.to :null }
                      endDate={formik.values?.from ?formik.values?.from :null}
                      maxDate={new Date(new Date(formik.values?.to ? formik.values?.to : new Date()).setTime(new Date(formik.values?.to ? formik.values?.to : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                      onChange={(update) => {
                        handleSelectDate(update[0], "to")
                        handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='from - to'
                      showDisabledMonthNavigation
                    />
                    <div>
                     <div className='text-danger'>{(formik?.touched?.from && formik?.errors?.from) || (formik?.touched?.to && formik?.errors?.to)}</div>
                     {/* <div className='text-danger'>{formik?.touched?.from && formik?.errors?.from}</div> */}
                    </div>
                  </div>
                  <div className="ed-box">
                    <label htmlFor="reportType" className="form-label">Report type <span className='req-error'>*</span></label>
                    <select className={`form-select ${formik?.touched?.reportType && formik?.errors?.reportType && "border-danger"}`} name='reportType' aria-label="Default select example" value={formik?.values?.reportType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="vendor">Vendor</option>
                      <option value="item">Item</option>
                    </select>
                  </div>
                  <p className='text-danger'>{formik?.touched?.reportType && formik?.errors?.reportType}</p>
                  <div className="ed-box">
                    <label htmlFor="vendorId" className="form-label">Vendor name</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.vendorId}
                      placeholder="Select "
                      isSearchable={true}
                      name="vendorId"
                      options={vendors}
                      onChange={(e)=>formik.setFieldValue("vendorId", e) }
                    />
                  </div>

                  <div className="ed-box">
                    <label htmlFor="serialNumber" >Challan/PO/Voucher Number</label>
                    <input type="serialNumber" className={`form-control ${formik?.touched?.serialNumber && formik?.errors?.serialNumber && "border-danger"}`} id="serialNumber" placeholder="Enter serial number" name='serialNumber' value={formik?.values?.serialNumber} onChange={e => {e.target.value = e.target.value?.toUpperCase() ; formik?.handleChange(e)}} onBlur={formik?.handleBlur} />
                    <p className='text-danger'>{formik?.touched?.serialNumber && formik?.errors?.serialNumber}</p>
                  </div>

                  <div className="ed-box">
                    <label htmlFor="Item" className="form-label">Item</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.itemName ? { label: formik?.values?.itemName,value:formik?.values?.itemName } : null}
                      placeholder="Select "
                      isSearchable={true}
                      name="itemName"
                      options={items}
                      onChange={(e) => {formik.setFieldValue("itemName", e.value) }}
                    />
                  </div>

                  <div className="ed-box">
                    <label htmlFor="status" className="form-label">Status</label>
                    <select className="form-select" name='status' aria-label="Default select example" value={formik?.values?.status} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="created">Created</option>
                      <option value="submitted">Submitted</option>
                      <option value="approved">Approved</option>
                    </select>
                  </div>

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <div className="save" onClick={(e) => { clickHandler()}}>
                      Apply
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterReport