import React from 'react'

const ConfirmationModal = (props) => {
    const {modalId,message,handleConfirm,title} = props;
    return (
        <div
            className="modal fade"
            id={modalId}
            tabIndex="-1"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-centered" style={{maxWidth:"490px"}}>
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button
                            id = "confirmModalCloseBtn"
                            type="button"
                            className="btn-close block-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body pe-0 ps-0 block-user">
                        <div className='fs-5 mb-2'>{title}</div>
                        {/* <img src="/images/blockuser.png" alt="" width={202} srcSet="" /> */}
                        <h1 className="mb-0 text-break">
                            {message}
                        </h1>
                    </div>
                    <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3" style={{padding:"1.25rem"}}>
                        {props?.okbtn === "no" ? <button
                            type="button"
                            className="btn-custom w-auto text-white"
                            data-bs-dismiss="modal"
                        >
                            Ok
                        </button>: <>
                        <button
                            type="button"
                            className="btn-custom w-auto text-white"
                            onClick={handleConfirm}
                            data-bs-dismiss="modal"
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn-custom w-auto bg-secondary "
                            data-bs-dismiss="modal"
                        >
                            No
                        </button>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal