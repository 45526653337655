import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, getPurchaseOrderApi } from '../../utils/ApiEndpointFunctions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MONTHS } from '../../utils/constant';
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import PurchaseTable from '../purchageOrder/PurchaseTable';
import Challan from '../challan/Challan';
import Voucher from '../voucher/Voucher';
import Cashbook from '../cashbook/cashbook';

function Dashboard() {
  const [selectedTab, setSelectedTab] = useState("purchase")
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <div className="dash-nav1">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0 font-blue">Dashboard</h3>
          {userDetails?.roleId?.permission?.includes("poWrite") && (searchParams.get("tab") == "purchase" || searchParams.get("tab") == null) && <button onClick={() => { navigate("/purchase/add"); }} className="create-order">Create Purchase Order</button>}
        </div>
      </div>
      <div className="dash-table">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button onClick={() => setSearchParams({ tab: 'purchase' })} className={`nav-link ${(searchParams.get("tab") == "purchase" || searchParams.get("tab") == null) && "active"}`} >Purchase order</button>
          </li>
          <li className="nav-item" role="presentation">
            <button onClick={() => setSearchParams({ tab: 'challan' })} className={`nav-link ${searchParams.get("tab") == "challan" && "active"}`} >Challan</button>
          </li>
          <li className="nav-item" role="presentation">
            <button onClick={() => setSearchParams({ tab: 'voucher' })} className={`nav-link ${searchParams.get("tab") == "voucher" && "active"}`} >Voucher</button>
          </li>
          {userDetails?.roleId?.permission?.includes("cashbookRead") &&  <li className="nav-item" role="presentation">
            <button onClick={() => setSearchParams({ tab: 'cashbook' })} className={`nav-link ${searchParams.get("tab") == "cashbook" && "active"}`} >My Cashbook</button>
          </li> }
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className={`tab-pane fade ${(searchParams.get("tab") == "purchase" || searchParams.get("tab") == null) && "show active"} `} id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
            <PurchaseTable type="dashboard" />
          </div>
          <div className={`tab-pane fade ${searchParams.get("tab") == "challan" && "show active"} `} >{searchParams.get("tab") == "challan" && <Challan type="dashboard" />}</div>
          <div className={`tab-pane fade ${searchParams.get("tab") == "voucher" && "show active"} `} >{searchParams.get("tab") == "voucher" && <Voucher type="dashboard" />}</div>
          <div className={`tab-pane fade ${searchParams.get("tab") == "cashbook" && "show active"} `}>{searchParams.get("tab") == "cashbook" && <Cashbook type="dashboard"/>}</div>
        </div>
      </div>
    </>
  )
}

export default Dashboard