
import { deleteApi, getApiWithToken, getDownloadApiWithToken, imgUploadApi, postApiWithoutToken, postApiWithToken, putApiWithToken } from "./ApiInterfaces"


export const signInApi = (payload) => {
  return postApiWithoutToken(`/user/login`, payload)
}

export const isExistApi = (payload) => {
  return postApiWithoutToken(`/user/isExist`, payload)
}

export const getProfileApi = () => {
  return getApiWithToken(`/user/getProfile`)
}

export const getPurchaseOrderApi = (Item="",date="",hsnCode="",number="",status="",vendorName="",limit="",page="") => {
  return getApiWithToken(`/purchaseOrder/getPurchaseOrders?ponum=${number}&vendor=${vendorName}&hsn=${hsnCode}&item=${Item}&status=${status}&date=${date}&limit=${limit}&page=${page}`)
}



export const getvendorsApi = (search = "", limit = "", page = "") => {
  return getApiWithToken(`/vendors/getvendors?search=${search}&limit=${limit}&page=${page}`)
}

export const getvendorsSuggetsonApi = (search = "", limit = "", page = "") => {
  return getApiWithToken(`/vendors/getVendorSuggestions?search=${search}&limit=${limit}&page=${page}`)
}

export const getVendorByGstApi = (gstNumber = "") => {
  return getApiWithToken(`/vendors/getVendorByGST?gstNumber=${gstNumber}`)
}

export const getItemsApi = (search = "", limit = "", page = "", vendorId="") => {
  return getApiWithToken(`/items/getItems?search=${search}&limit=${limit}&page=${page}&vendorId=${vendorId}`)
}

export const getItemSuggestionApi = (search = "", limit = "", page = "", vendorId = "") => {
  return getApiWithToken(`/items/getItemSuggestion?search=${search}&limit=${limit}&page=${page}&vendorId=${vendorId}`)
}

export const createItemApi = (payload) => {
  return postApiWithToken(`/items/createItem`, payload)
}

export const createPurchaseOrderApi = (payload) => {
  return postApiWithToken(`/purchaseOrder/createPurchaseOrder`, payload)
}

export const updateItemByIdApi = (payload) => {
  return putApiWithToken(`/items/updateItemById`, payload)
}

export const termsAndConditionsApi = (id) => {
  return getApiWithToken(`/purchaseOrder/termsAndConditions`)
}

export const getPurchaseOrderByIdApi = (id) => {
  return getApiWithToken(`/purchaseOrder/getPurchaseOrderById?id=${id}`)
}

export const updatePurchaseOrderApi = (payload) => {
  return putApiWithToken(`/purchaseOrder/updatePurchaseOrder`, payload)
}

export const approvePurchaseOrderApi = (payload) => {
  return postApiWithToken(`/purchaseOrder/approvePurchaseOrder`, payload)
}

export const createVendorApi = (payload) => {
  return postApiWithToken(`/vendors/createVendor`, payload)
}

export const updateVendorByIdApi = (payload) => {
  return putApiWithToken(`/vendors/updateVendorById`, payload)
}

export const deleteItemByIdApi = (itemId,vendorId="") => {
  return deleteApi(`/items/deleteItemById?id=${itemId}&vendorId=${vendorId}`)
}

export const makeCopyApi = (payload) => {
  return postApiWithToken(`/purchaseOrder/makeCopy`, payload)
}

export const submitAndConvertToPurchaseOrderApi = (payload) => {
  return postApiWithToken(`/purchaseOrder/submitAndConvertToPurchaseOrder`, payload)
}

export const convertToPurchaseApi = (payload) => {
  return postApiWithToken(`/challan/convertToPurchase`, payload)
}

export const getChallansApi = (item = "", date = "", hsnCode = "", number = "", status = "", vendorName = "", limit = "", page = "") => {
  return getApiWithToken(`/challan/getChallans?ponum=${number}&vendor=${vendorName}&hsn=${hsnCode}&item=${item}&status=${status}&date=${date}&limit=${limit}&page=${page}`)
}

export const getChallanByIdApi = (id) => {
  return getApiWithToken(`/challan/getChallanById?id=${id}`)
}

export const fileUploadApi = (payload) => {
  return imgUploadApi(`/user/uploadFile`, payload)
}

export const submitForApprovalChallanApi = (payload) => {
  return postApiWithToken(`/challan/submitForApproval`, payload);
}

export const challanApproveOrRejectApi = (payload) => {
  return putApiWithToken(`/challan/approveOrReject`,payload)
}

export const challanSaveAsDraftApi = (payload) => {
  return putApiWithToken(`/challan/saveAsDraft`, payload);
}


export const addDocumentApi = (payload) => {
  return postApiWithToken(`/document/addDocument `, payload);
}

export const getDocumentsApi = (search = "", limit = "", page = "") => {
  return getApiWithToken(`/document/getDocuments?search=${search}&limit=${limit}&page=${page}`)
}

export const statesAndCitiesApi = (stateId="") => {
  return getApiWithToken(`/user/statesAndCities?stateId=${stateId}`)
}

export const getVouchersApi = (vendorType = "", name = "", challanNumber = "", voucherType = "", expenseType = "", date = "", limit = "", page = "") => {
  return getApiWithToken(`/voucher/getVouchers?vendorType=${vendorType}&name=${name}&challanNumber=${challanNumber}&voucherType=${voucherType}&expenseType=${expenseType}&date=${date}&limit=${limit}&page=${page}`)
}

export const getCashbooksApi = (vendorType = "", name = "", challanNumber = "", voucherType = "", expenseType = "", date = "", limit = "", page = "") => {
  return getApiWithToken(`/cashbook/cashbooks?vendorType=${vendorType}&name=${name}&challanNumber=${challanNumber}&voucherType=${voucherType}&expenseType=${expenseType}&date=${date}&limit=${limit}&page=${page}`)
}

export const createVoucherApi = (payload) => {
  return postApiWithToken(`/voucher/createVoucher `, payload);
}

export const updateVoucherApi = (payload) => {
  return putApiWithToken(`/voucher/updateVoucherById `, payload);
}

export const getVoucherByIdApi = (id) => {
  return getApiWithToken(`/voucher/getVoucherById?_id=${id}`)
}

export const approveOrRejectApi = (payload) => {
  return putApiWithToken(`/voucher/approveOrReject`,payload)
}

export const getSitesList = (payload) => {
  return getApiWithToken(`/user/userSites`)
}

export const switchSite = (payload) => {
  return putApiWithToken(`/user/switchUserSite`,payload)
}

export const getSiteDetailsById = (id) => {
  return getApiWithToken(`/user/getUserSiteById?_id=${id || ""}`)
}

export const getVoucherSuggestionNameApi = (type = "", search = "") => {
  return getApiWithToken(`/voucher/suggestName?type=${type}&search=${search}`)
}

export const getVoucherRemainingAmountApi = (payload) => {
  return postApiWithToken(`/voucher/checkRemainingAmount`, payload)
}

export const getUnitTypeApi = () => {
  return getApiWithToken(`/items/getUnitType`)
}

//  download po
export const downloadPOPdf = (id) => {
  return getDownloadApiWithToken(`/purchaseOrder/downloadPurchaseOrderById?id=${id}`)
}

//  download challan
export const downloadChallanPdf = (id) => {
  return getDownloadApiWithToken(`/challan/downloadChallanById?id=${id}`)
}

//  download voucher
export const downloadVoucherPdf = (id) => {
  return getDownloadApiWithToken(`/voucher/downloadVoucherById?_id=${id}`)
}

export const getSiteItemApi = () => {
  return getApiWithToken(`/items/getSiteItems`)
}

export const getReportApi = (vendorId,date,status,serialNumber,itemName,reportType) => {
  return getApiWithToken(`/report/view?vendorId=${vendorId || ""}&date=${date}&status=${status}&serialNo=${serialNumber}&itemName=${itemName || ""}&reportType=${reportType}`)
}

export const deleteDocumentApi = (data) => {
  return deleteApi(`/document/deleteDocumentById`,data)
}
