import React, { useEffect, useRef, useState } from 'react'
import { challanSaveAsDraftApi, fileUploadApi, getChallanByIdApi, getItemsApi, submitForApprovalChallanApi, updateItemByIdApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import { addItemInitialValue, addItemValidationSchema, challanInitalValue, challanValidationSchema } from '../../utils/Validation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';
import { S3_URL } from '../../utils/BaseUrl';
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import ItemsAddModal from '../../components/modal/ItemsAddModal';
import { debounce } from 'lodash';
import ImageViewModal from '../../components/modal/ImageViewModal';
import Spinwheel from '../../components/common/Spinwheel';
import moment from 'moment';
import { acceptAlphaAndNumOnly, convertByteToMB, formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import Tooltip from 'react-tooltip-lite';
import { FILE_ACCEPT, FILE_SIZE_ERROR, INVALID_FILE_FORMAT_ERROR_MESSAGE, MAX_FILE_SIZE } from '../../utils/constant';
import StatusModal from '../../components/modal/StatusModal';
import ConfirmationModal from '../../components/modal/ConfirmationModal';

function AddChallan(props) {
  const params = useParams();
  const { id } = params;
  const sigCanvas = useRef({});
  const [submitClick, setSubmitClick] = useState(false);
  const addItemModalId = 'addItemModalId';
  const updateItemModalId = "UpdateItemModalId";
  const viewImageModalId = 'viewImageModalId';
  const statusModalId = "statusModal"
  const submitConfirmModal = "submitConfirmModal"
  const [addItemLoader, setAddItemLoader] = useState(false)
  const [selectImage, setSelectImage] = useState("")
  const navigate = useNavigate();
  const [clickText, setClickText] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [taxItems, setTaxItems] = useState({});
  const [selectedItem, setSelectedItem] = useState();
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [draftLoader, setDraftLoader] = useState(false)

  const getItemFormat = (e) => {
    return {
      itemId: e._id,
      quantity: e.totalQuantity,
      unitPrice: e.unitPrice,
      itemDetails: e,
      isBreakage: e.isBreakage,
      breakage: e.breakage
    }
  }
  const itemSelectHandler = (e) => {
    const item = getItemFormat(e)
    challanFormik.setFieldValue("items", [...challanFormik.values.items, item])
  }

  const updateItemHandler = (ele) => {

    itemFormik.setValues(ele?.itemDetails)
    setSelectedItem(ele?.itemDetails?._id);
    itemFormik.setFieldValue(`unitPrice`, ele.unitPrice)
    itemFormik.setFieldValue(`totalQuantity`, ele.quantity)
    itemFormik.setFieldValue(`isBreakage`, ele.isBreakage)
    itemFormik.setFieldValue(`breakage`, ele.breakage)
  }

  // const itemsSubmit = async (values) => {
  //   try {
  //     setAddItemLoader(true)
  //     const res = selectedItem ? await updateItemByIdApi(values) : await createItemApi({ ...values, vendorId: challanFormik.values.purchaseOrder?.vendor?._id })
  //     if (res.status === 200) {
  //       toast.success(res.data.message)
  //       console.log(values,'ye hai values ')
  //       if (values._id && challanFormik && challanFormik.values && challanFormik.values.items) {
  //         challanFormik.values.items = challanFormik?.values?.items?.filter(ele => { return (ele.itemId !== values._id) })
  //       }
  //       itemSelectHandler(values)
  //       document.getElementById(!(clickText === "update") ? addItemModalId : updateItemModalId).click()
  //     }
  //   } catch (error) {
  //     error && error.response ?
  //       toast.error(error.response?.data?.message) :
  //       toast.error("something went wrong");
  //   }
  //   setAddItemLoader(false)
  // }

  const itemsSubmit = async (values) => {
    try {
      if (values?.unitType?.toLowerCase() != "other") {
        values.otherUnit = ""
      }
      if (values._id && challanFormik && challanFormik.values && challanFormik.values.items) {
        if (selectedItem) {
          challanFormik.values.items = challanFormik.values.items.map(item => {
            if (item.itemId == selectedItem) {
              return getItemFormat(values);
            }
            return item;
          });
        } else {
          const findAlreadyExist = challanFormik.values.items.find(obj => obj.itemId == values._id)
          if (findAlreadyExist) {
            toast.error("Item Already Exist")
          } else {
            itemSelectHandler(values)
          }
        }
      }
      document.getElementById(!(clickText === "update") ? addItemModalId : updateItemModalId).click()
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
  }


  const itemFormik = useFormik({
    initialValues: addItemInitialValue,
    onSubmit: itemsSubmit,
    validationSchema: addItemValidationSchema
  })



  let imageArray = ["truckFrontSide", "truckBackSide", "beforeUnload", "afterUnload", "challanFrontSide", "challanBackSide", "invoiceFrontSide", "invoiceBackSide", "signature"]
  let addressKey = ["address1", "address2", "city", "state", "pinCode"];

  const getFileName = (name) => {
    const fileNames = {
      "truckFrontSide": "Truck Front Side",
      "truckBackSide": "Truck Back Side",
      "beforeUnload": "Before Unload Items",
      "afterUnload": "After Unload Items",
      "challanFrontSide": "Challan Front Side",
      "challanBackSide": "Challan Back Side",
      "invoiceFrontSide": "Invoice Front Side",
      "invoiceBackSide": "Invoice Back Side",
      "signature": "Delivery person signature"
    }

    return fileNames[name] || ""
  }

  const clear = () => {
    sigCanvas.current.clear();
  };

  const save = async () => {
    try {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      const blob = await new Promise(resolve => canvas.toBlob(resolve));
      const formData = new FormData();
      formData.append('file', blob, `signature${new Date().getTime()}.png`);
      formData.append("type", "challan")
      const res = await fileUploadApi(formData)
      if (res.status === 200 && res.data?.status === 1) {
        toast.success("File uploaded");

        challanFormik.setFieldValue("signature", res.data?.data)
        document.getElementById("signatureClose").click()

      }
    } catch (error) {
      console.log(error)
    }
  }

  const fileUploadHandler = async (e, pathName) => {
    try {

      const file1 = e.target.files[0];

      const file = new File([file1], `${new Date().getTime()}_${file1.name}`, {
        type: file1.type,
        lastModified: file1.lastModified,
      });


      if (file) {

        if (!FILE_ACCEPT.includes(file.type)) {
          toast.error(INVALID_FILE_FORMAT_ERROR_MESSAGE)
          return;
        }

        const fileSizeInMB = convertByteToMB(file.size || 0);

        if (fileSizeInMB > MAX_FILE_SIZE) {
          return toast.error(FILE_SIZE_ERROR)
        }

        // challanFormik.setFieldTouched(pathName, true)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "challan")

        const res = await fileUploadApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          challanFormik.setFieldValue(pathName, res.data?.data)

        }
      }

    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("There was an error uploading your file. Please try again later.")
      }
    }

  }

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails?.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * item.quantity
      })
      return price
    }
    return 0;
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }

  const addressFormatter = (vendor) => {
    let address = ""

    addressKey.forEach((ele, index) => {
      if (vendor?.[ele]) {
        address += vendor?.[ele] + ((addressKey.length - 1) === index ? "" : ", ");
      }
    });
    return address
  }

  const removeItem = (removeId) => {
    challanFormik.setFieldValue("items", challanFormik?.values?.items?.filter(item => item.itemId !== removeId))
    document.getElementById(updateItemModalId).click();
  }



  const challanSubmit = async (values) => {
    try {
      setSubmitLoader(true)
      const amt = totalAmount(values?.items)

      values.roundOffAmount = getRoundOff(amt)
      values.amount = Math.round(amt)

      const res = await submitForApprovalChallanApi(values)
      if (res.status === 200) {
        toast.success(res.data.message)
        document.getElementById(submitConfirmModal).click()
        setConfirmationMsg(`Challan ${res.data?.data?.challanNumber || ""} is successfully submitted.`)
        document.getElementById("statusModalBtn").click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setSubmitLoader(false)
  }


  const challanFormik = useFormik({
    initialValues: challanInitalValue,
    onSubmit: challanSubmit,
    validationSchema: challanValidationSchema
  })

  const getChallanById = async (id) => {
    try {
      const res = await getChallanByIdApi(id)
      if (res.status === 200) {
        const challan = res.data?.data?.challan


        if (challan?.challanFiles?.[0]?.otherFiles?.length > 0) {
          let items = []
          for (var i = challan?.challanFiles?.[0]?.otherFiles?.length > 0 ? challan?.challanFiles?.[0]?.otherFiles?.length : 0; i < 5; i++) {
            items.push({
              description: "", image: "",
            })
          }
          challanFormik.setFieldValue("otherFiles", [...challan?.challanFiles?.[0]?.otherFiles, ...items])
        }

        challanFormik.setFieldValue("id", challan?._id);
        challanFormik.setFieldValue("truckNumber", challan?.truckNumber)
        challanFormik.setFieldValue("taxInvoiceNumber", challan?.taxInvoiceNumber)
        challanFormik.setFieldValue("taxInvoiceDate", challan?.taxInvoiceDate)
        challanFormik.setFieldValue("items", challan?.challanItems)
        challanFormik.setFieldValue("truckFrontSide", challan?.challanFiles?.[0]?.truckFrontSide)
        challanFormik.setFieldValue("truckBackSide", challan?.challanFiles?.[0]?.truckBackSide)
        challanFormik.setFieldValue("beforeUnload", challan?.challanFiles?.[0]?.beforeUnload)
        challanFormik.setFieldValue("afterUnload", challan?.challanFiles?.[0]?.afterUnload)
        challanFormik.setFieldValue("challanFrontSide", challan?.challanFiles?.[0]?.challanFrontSide)
        challanFormik.setFieldValue("challanBackSide", challan?.challanFiles?.[0]?.challanBackSide)
        challanFormik.setFieldValue("invoiceFrontSide", challan?.challanFiles?.[0]?.invoiceFrontSide)
        challanFormik.setFieldValue("invoiceBackSide", challan?.challanFiles?.[0]?.invoiceBackSide)
        challanFormik.setFieldValue("signature", challan?.challanFiles?.[0]?.signature);
        challanFormik.setFieldValue("purchaseOrder", challan?.purchaseOrder);
        challanFormik.setFieldValue("challanNumber", challan?.challanNumber);
        challanFormik.setFieldValue("createdAt", challan?.createdAt);
        challanFormik.setFieldValue("status", challan?.status);
        challanFormik.setFieldValue("remarks", challan?.remarks || "");
      }
    } catch (error) {
      toast.error("Something went wrong")
    }
  }

  const previewTaxItems = (items) => {
    let taxItem = {};
    items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }

  useEffect(() => {
    getChallanById(id)
  }, [])

  useEffect(() => {
    previewTaxItems(challanFormik.values.items)
  }, [challanFormik.values.items])



  const handleChallanSubmit = () => {
    setSubmitClick(true);
    challanFormik.handleSubmit()
  }

  const checkValidation = () => {
    const errors = Object.keys(challanFormik.errors)
    console.log("errors:", errors);
    if (errors?.length > 0) {
      setSubmitClick(true);
      challanFormik.handleSubmit()
    } else {
      document.getElementById("submitModalBtn").click()
    }
  }

  const handleSaveAsDraft = async () => {
    try {
      setDraftLoader(true)
      const res = await challanSaveAsDraftApi(challanFormik?.values)
      if (res.status === 200) {
        toast.success(res.data.message)
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setDraftLoader(false)
  }

  const handleCloseStatusModal = () => {
    navigate("/challan")
  }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className="dash-nav1">
                <div className="back cursor-pointer" onClick={() => { window.history.back() }}><i className="bi bi-chevron-left"></i> Back</div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0 fw-bold font-blue">{challanFormik.values.purchaseOrder?.vendor?.name}</h5>
                  <div>
                    {challanFormik.values.status === "created" && <button className="add-item me-2" disabled={submitLoader || draftLoader} onClick={handleSaveAsDraft}>{draftLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Save As Draft"}</button>}
                    {/* <button className="add-item" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { setSelectedItem(""); setClickText("add"); itemFormik.resetForm() }} ><i className="bi bi-plus-lg"></i> Add new item</button> */}
                    <button className="create-order" disabled={submitLoader || draftLoader} onClick={checkValidation}>{submitLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit for Approval"}</button>
                  </div>
                </div>
              </div>

              <div className='dash-table'>
                <div className='container'>
                  <div className="row">
                    <div className='col-lg-4'>
                      <div className="vendor-detail order-review">
                        <div className="d-flex justify-content-between mb-1">
                          <div className='fs-5 fw-bold'>{challanFormik.values.purchaseOrder?.vendor?.name}</div>
                          <div><span className='badge bg-warning'>step-1</span></div>
                        </div>
                        <hr />

                        <div className="d-flex justify-content-between form-details">
                          <label>PO Number</label>
                          <div>{challanFormik.values.purchaseOrder?.poNumber}</div>
                        </div>

                        <div className="d-flex justify-content-between form-details">
                          <label >Created date</label>
                          <div>{moment(challanFormik.values.purchaseOrder?.createDate).format("DD/MM/YYYY")}</div>
                        </div>
                        <hr />

                        <div className='fs-5 fw-bold'>
                          Shipment details
                        </div>
                        <hr />

                        <div className="form-details">
                          <label htmlFor="truckNumber" className="form-label">Truck number <span className='req-error'>*</span></label>
                          <input type="text" className="form-control" id="truckNumber" placeholder="Enter " name='truckNumber' value={challanFormik?.values?.truckNumber} onChange={(e) => {
                            if (acceptAlphaAndNumOnly.test(e.target.value)) {
                              challanFormik?.setFieldValue("truckNumber", e.target.value.toUpperCase());
                            }
                          }}
                            onBlur={challanFormik?.handleBlur} />
                          {challanFormik?.touched?.truckNumber && challanFormik?.errors?.truckNumber ? (<div className='req-error'>{challanFormik?.errors?.truckNumber}</div>) : null}
                        </div>

                        <div className="form-details">
                          <label htmlFor="taxInvoiceNumber" className="form-label">TAX invoice number</label>
                          <input type="text" className="form-control" id="taxInvoiceNumber" placeholder="Enter " name='taxInvoiceNumber' value={challanFormik?.values?.taxInvoiceNumber} onChange={(e) => {
                            challanFormik?.setFieldValue("taxInvoiceNumber", e.target.value.toUpperCase());
                          }} onBlur={challanFormik?.handleBlur} />
                          {challanFormik?.touched?.taxInvoiceNumber && challanFormik?.errors?.taxInvoiceNumber ? (<div className='req-error'>{challanFormik?.errors?.taxInvoiceNumber}</div>) : null}
                        </div>

                        <div className="form-details w-100 d-flex flex-column">
                          <label htmlFor="taxInvoiceDate">TAX invoice date</label>

                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-control w-100 cursor-pointer' name='createDate' selected={challanFormik.values?.taxInvoiceDate} onChange={(e) => { challanFormik.setFieldValue("taxInvoiceDate", new Date(e).toISOString()) }} />
                          {challanFormik?.touched?.taxInvoiceDate && challanFormik?.errors?.taxInvoiceDate ? (<div className='req-error'>{challanFormik?.errors?.taxInvoiceDate}</div>) : null}

                        </div>

                        <hr />

                        <div>
                          {/* <div className=''>
                            <span className='fs-5 fw-bold'>Items</span>  {`Count:(${challanFormik.values?.items?.length})`}
                          </div> */}
                          <div className='d-flex justify-content-between align-items-center bb'>
                            <div className=''>
                              <span className='fs-5 fw-bold'>Items</span>  {`Count:(${challanFormik.values?.items?.length})`}
                            </div>
                            <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { setSelectedItem(""); setClickText("add"); itemFormik.resetForm() }} >
                              <i className="bi bi-plus"></i> Add new Item
                            </button>
                          </div>
                          <div className='table-responsive'>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th style={{ width: "30%" }} scope="col">NAME</th>
                                  <th scope="col">QTY</th>
                                  <th scope="col">RATE</th>
                                  <th scope="col">UNIT</th>
                                  <th scope="col">EDIT</th>
                                </tr>
                              </thead>
                              <tbody>


                                {challanFormik?.values?.items?.map((ele, index) => {

                                  return (<tr key={"asdfs" + index}>
                                    <td>{ele?.itemDetails?.name}</td>
                                    <td>
                                      <div>
                                        <input type="text" className="form-control qt-box" name={`items[${index}].quantity`} value={challanFormik.values.items?.[index].quantity} onChange={challanFormik.handleChange} onBlur={challanFormik.handleBlur} />
                                        {challanFormik?.touched?.items?.[index]?.quantity && challanFormik?.errors?.items?.[index]?.quantity ? (<div className='req-error'>{challanFormik?.errors?.items?.[index]?.quantity}</div>) : null}</div>
                                    </td>
                                    <td>
                                      <div>
                                        <input type="text" className="form-control qt-box" name={`items[${index}].unitPrice`} value={challanFormik.values.items?.[index].unitPrice} onChange={challanFormik.handleChange} onBlur={challanFormik.handleBlur} />
                                        {challanFormik?.touched?.items?.[index]?.unitPrice && challanFormik?.errors?.items?.[index]?.unitPrice ? (<div className='req-error'>{challanFormik?.errors?.items?.[index]?.unitPrice}</div>) : null}</div>
                                    </td>
                                    <td>
                                      <div title={ele?.itemDetails?.unitType}>
                                        <input type="text" className="form-control un-box" name={`items[${index}].unit`} disabled value={ele?.itemDetails?.unitType} />
                                        {/* {challanFormik?.touched?.items?.[index]?.unitPrice && challanFormik?.errors?.items?.[index]?.unitPrice ? (<div className='req-error'>{challanFormik?.errors?.items?.[index]?.unitPrice}</div>) : null} */}
                                      </div>
                                    </td>
                                    <td><div data-bs-toggle="modal" data-bs-target={`#${updateItemModalId}`} onClick={() => { setClickText("update"); updateItemHandler(ele) }}  ><span className="bi bi-pencil-square point fs-5"></span></div></td>
                                  </tr>)
                                })
                                }

                              </tbody>
                            </table>
                          </div>


                          {/* <div className='table-responsive mt-3'>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">OTY</th>
                              <th scope="col">Unit</th>
                              
                              <th scope="col">edit</th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {challanFormik?.values?.items?.map((ele, index) => {

                              return (
                                <tr key={"asdfs" + index}>
                                  <td><div>{ele?.itemDetails?.name}</div></td>
                                  <td><div><input type="text" className='form-control' name={`items[${index}].quantity`} value={challanFormik.values.items?.[index].quantity} onChange={challanFormik.handleChange} onBlur={challanFormik.handleBlur} /></div></td>
                                  <td><div><input type="text" className='form-control' name={`items[${index}].unit`} disabled value={ele?.itemDetails?.unitType} /></div></td>
                                 <td><div data-bs-toggle="modal" data-bs-target={`#${updateItemModalId}`} onClick={() => {setClickText("update");updateItemHandler(ele)}}  ><span className="fe fe-edit-3"></span></div></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div> */}
                        </div>
                        <div className="form-details">
                          <label htmlFor="remarks" className="form-label">Remarks</label>
                          <textarea className="w-100 form-control" type="text" name={`remarks`} placeholder="Enter note..." disabled="" onChange={challanFormik.handleChange} value={challanFormik?.values?.remarks} />
                          {challanFormik?.touched?.remarks && challanFormik?.errors?.remarks ? (<div className='req-error'>{challanFormik?.errors?.remarks}</div>) : null}
                        </div>

                        {/* <hr /> */}
                        <div>
                          <div className='fs-5 fw-bold'>Delivery person signature <span className='req-error'>*</span></div>
                          <div className='py-2'>

                            <div className="d-flex border px-2 py-1 justify-content-between align-items-center ">
                              <div className="">
                                <div className='border rounded-image d-flex justify-content-center align-items-center'>
                                  <img src={S3_URL + challanFormik.values?.signature} alt="" style={{ maxHeight: "85%", maxWidth: "85%" }} />
                                </div>
                                {(challanFormik?.touched?.signature || submitClick) && challanFormik?.errors?.signature ? (<div className='req-error'>{challanFormik?.errors?.signature}</div>) : null}

                              </div>

                              <div className='signatureCanvasParent'>
                                <Popup
                                  modal
                                  trigger={<div className='create-order cursor-pointer'>
                                    Add Signature
                                  </div>}
                                  closeOnDocumentClick={false}

                                >
                                  {close => (
                                    <>
                                      <SignaturePad
                                        ref={sigCanvas}
                                        canvasProps={{
                                          className: "signatureCanvas"
                                        }}

                                      />

                                      <div className='bg-dark-soft p-2'>
                                        <button className='btn btn-primary mx-1' id="signatureSave" onClick={save} >Upload</button>
                                        <button className='btn btn-primary mx-1' id="signatureClear" onClick={clear}>Clear</button>
                                        <button className='btn btn-primary mx-1' id="signatureClose" onClick={close}>Close</button>
                                      </div>
                                    </>
                                  )}
                                </Popup>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-3'>
                      <div className="items-detail-box order-review">
                        <div>
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Truck photo</div>
                            <div><span className='badge bg-warning'>step-2</span></div>
                          </div>
                          <hr />

                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100" >
                            <div className=''>
                              <label className='w-75'>Front side <span className='req-error'>*</span></label>
                              {(challanFormik?.touched?.truckFrontSide || submitClick) && challanFormik?.errors?.truckFrontSide ? (<div className='req-error'>{challanFormik?.errors?.truckFrontSide}</div>) : null}
                            </div>
                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="truckFrontSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" className='btn btn_upload_file d-none ' name="truckFrontSide" id='truckFrontSide' onChange={(e) => { fileUploadHandler(e, "truckFrontSide") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.truckFrontSide ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.truckFrontSide} alt="" className='img-fluid' />}
                              </label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100" >
                            <div className='w-75'>
                              <label>Back side <span className='req-error'>*</span></label>
                              {(challanFormik?.touched?.truckBackSide || submitClick) && challanFormik?.errors?.truckBackSide ? (<div className='req-error'>{challanFormik?.errors?.truckBackSide}</div>) : null}
                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }}  >
                              <label htmlFor="truckBackSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" id='truckBackSide' className='btn btn_upload_file d-none' onChange={(e) => { fileUploadHandler(e, "truckBackSide") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.truckBackSide ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.truckBackSide} alt="" className='img-fluid' />}
                              </label>
                            </div>

                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                            <div className='w-75'>
                              <label>Before unload all items</label>
                              {(challanFormik?.touched?.beforeUnload || submitClick) && challanFormik?.errors?.beforeUnload ? (<div className='req-error'>{challanFormik?.errors?.beforeUnload}</div>) : null}

                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="beforeUnload" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" className='btn btn_upload_file d-none' id='beforeUnload' onChange={(e) => { fileUploadHandler(e, "beforeUnload") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.beforeUnload ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.beforeUnload} alt="" className='img-fluid' />}
                              </label>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                            <div className='w-75'>
                              <label>After unload all items</label>
                              {(challanFormik?.touched?.afterUnload || submitClick) && challanFormik?.errors?.afterUnload ? (<div className='req-error'>{challanFormik?.errors?.afterUnload}</div>) : null}

                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0 ' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="afterUnload" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>

                                <input type="file" id='afterUnload' className='btn btn_upload_file d-none' onChange={(e) => { fileUploadHandler(e, "afterUnload") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.afterUnload ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.afterUnload} alt="" className='img-fluid' />}
                              </label>
                            </div>

                          </div>
                        </div>

                        <div>
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Vendor challan photo</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                            <div className='w-75'>
                              <label >Challan front side <span className='req-error'>*</span></label>
                              {(challanFormik?.touched?.challanFrontSide || submitClick) && challanFormik?.errors?.challanFrontSide ? (<div className='req-error'>{challanFormik?.errors?.challanFrontSide}</div>) : null}

                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="challanFrontSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" id='challanFrontSide' className='btn btn_upload_file d-none' onChange={(e) => { fileUploadHandler(e, "challanFrontSide") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.challanFrontSide ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.challanFrontSide} alt="" className='img-fluid' />}
                              </label>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                            <div className='w-75'>
                              <label>Challan back side <span className='req-error'>*</span></label>
                              {(challanFormik?.touched?.challanBackSide || submitClick) && challanFormik?.errors?.challanBackSide ? (<div className='req-error'>{challanFormik?.errors?.challanBackSide}</div>) : null}
                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="challanBackSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" id='challanBackSide' className='btn btn_upload_file d-flex d-none' onChange={(e) => { fileUploadHandler(e, "challanBackSide") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.challanBackSide ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.challanBackSide} alt="" className='img-fluid' />}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Tax invoice photo</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                            <div className='w-75'>
                              <label>Invoice front side </label>
                              {/* {(challanFormik?.touched?.invoiceFrontSide || submitClick) && challanFormik?.errors?.invoiceFrontSide ? (<div className='req-error'>{challanFormik?.errors?.invoiceFrontSide}</div>) : null} */}

                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="invoiceFrontSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" id='invoiceFrontSide' className='btn btn_upload_file d-none' onChange={(e) => { fileUploadHandler(e, "invoiceFrontSide") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.invoiceFrontSide ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.invoiceFrontSide} alt="" className='img-fluid' />}

                              </label>
                            </div>

                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                            <div className='w-75'>
                              <label>Invoice back side </label>
                              {/* {(challanFormik?.touched?.invoiceBackSide || submitClick) && challanFormik?.errors?.invoiceBackSide ? (<div className='req-error'>{challanFormik?.errors?.invoiceBackSide}</div>) : null} */}

                            </div>

                            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                              <label htmlFor="invoiceBackSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <input type="file" id='invoiceBackSide' className='btn btn_upload_file d-flex d-none' onChange={(e) => { fileUploadHandler(e, "invoiceBackSide") }} accept={FILE_ACCEPT} />
                                {!challanFormik.values?.invoiceBackSide ? <span className="bi bi-paperclip m-auto"></span> :
                                  <img src={S3_URL + challanFormik.values?.invoiceBackSide} alt="" className='img-fluid' />}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Other photo</div>
                          </div>
                          {challanFormik.values.otherFiles?.map((ele, index) => {

                            return (
                              <div key={index} className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
                                <div className='w-75'>
                                  <label>Description</label>
                                  <input type="text" className='form-control form-control-sm border-top-0 border-end-0 border-start-0' placeholder='write about your photo' name={`otherFiles[${index}].description`} value={ele?.description} onChange={challanFormik.handleChange} />
                                  {(challanFormik?.touched?.otherFiles?.[index]?.description || submitClick) && challanFormik?.errors?.otherFiles?.[index]?.description ? (<div className='req-error'>{challanFormik?.errors?.otherFiles?.[index]?.description}</div>) : null}

                                </div>

                                <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                                  <label htmlFor={`otherFiles[${index}].image`} style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>

                                    <input type="file" id={`otherFiles[${index}].image`} className='btn btn_upload_file d-none' onChange={(e) => { fileUploadHandler(e, `otherFiles[${index}].image`) }} accept={FILE_ACCEPT} />
                                    {!ele?.image ? <span className="bi bi-paperclip m-auto"></span> :
                                      <img src={S3_URL + ele?.image} alt="" className='img-fluid' />}
                                  </label>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className="order-review">
                        <div>
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Challan preview</div>

                          </div>
                          <hr />

                          <div>
                            <div className='fs-6 fw-bold'>
                              {challanFormik.values.purchaseOrder?.vendor?.name}
                            </div>

                            <div className='mt-3 d-flex justify-content-between form-details'>
                              <label>Challan number</label>
                              <div>{challanFormik.values?.challanNumber}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>Challan created date</label>

                              <div>{moment(challanFormik.values?.createdAt).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>PO number</label>
                              <div>{challanFormik.values.purchaseOrder?.poNumber}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>PO date</label>
                              <div>{moment(challanFormik.values.purchaseOrder?.createdDate).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>GST number</label>
                              <div>{challanFormik.values.purchaseOrder?.vendor?.gstNumber}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>Due date</label>
                              <div>{moment(challanFormik.values.purchaseOrder?.dueDate).format("DD/MM/YYYY")}</div>
                            </div>
                            <hr />
                            <div className='fs-5 fw-bold'>Address:</div>
                            <div>{addressFormatter(challanFormik.values.purchaseOrder?.vendor?.vendorAddressId)}</div>
                          </div><hr />
                          <div className='mt-3'>
                            <div className='fs-5 fw-bold'>
                              Shipment details
                            </div>
                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>Truck number</label>
                              <div>{challanFormik.values?.truckNumber}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between form-details'>
                              <label>TAX invoice number</label>
                              <div>{challanFormik.values?.taxInvoiceNumber}</div>
                            </div>
                            <div className='mt-3 d-flex justify-content-between form-details'>
                              <label>TAX invoice Date</label>
                              <div>{moment(challanFormik?.values?.taxInvoiceDate).format("DD/MM/YYYY")}</div>
                            </div>
                          </div>

                          <hr />
                          <div>
                            <div className=''>
                              <span className='fs-5 fw-bold'>Items</span>  {`Count:(${challanFormik.values?.items?.length})`}
                            </div>

                            <div className='table-responsive mt-3'>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">GST</th>
                                    <th scope="col">QTY</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Breakage</th>
                                  </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                  {
                                    challanFormik.values?.items.map((ele, index) => {

                                      return (
                                        <tr key={index}>
                                          <td>{ele?.itemDetails?.name}</td>
                                          <td>{ele?.itemDetails?.tax}%</td>
                                          <td>{ele.quantity}</td>
                                          <td>{ele.unitPrice}</td>
                                          <td>{(ele.quantity * ele.unitPrice)}</td>
                                          <td>{(ele.breakage ?
                                            <Tooltip
                                              content={(
                                                <div  >
                                                  {ele.breakage}
                                                </div>
                                              )}
                                              direction="up"
                                              tagName="div"
                                              className="target d-inline"
                                              background='#333'
                                              tipContentClassName="text-light font-weight-bold"
                                              styles={{ cursor: "pointer" }}
                                            >
                                              Yes
                                            </Tooltip> : "No")}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                              </table>

                              <div className='amount-box p-2 px-3'>
                                <div className="d-flex justify-content-between gap-2 mt-3">
                                  <div className="fw-bold">Amount</div>
                                  <div>{formatPrice(totalPrice(challanFormik.values?.items))}</div>
                                </div>

                                {(Object.keys(taxItems).sort((a, b) => b - a)).map((item, index) => {

                                  return (
                                    <div key={index}>
                                      <div className="d-flex justify-content-between gap-2 ">
                                        <p className="dark">CGST ({item / 2}% on {totalPrice(taxItems[item])?.toFixed(2)}) </p>
                                        <p className="light">{formatPrice((totalPrice(taxItems[item]) * item / 2) / 100)}</p>
                                      </div>
                                      <div className="d-flex justify-content-between gap-2 ">
                                        <p className="dark">SGST ({item / 2}% on {totalPrice(taxItems[item])?.toFixed(2)}) </p>
                                        <p className="light">{formatPrice((totalPrice(taxItems[item]) * item / 2) / 100)}</p>
                                      </div>
                                    </div>
                                  )
                                })}
                                <hr />
                                <div className="d-flex total justify-content-between align-items-center">
                                  <p>R/off</p>
                                  <p>{getRoundOff(totalAmount(challanFormik.values?.items)).toFixed(2)}</p>
                                </div>
                                <div className="d-flex justify-content-between gap-2 fw-bold">
                                  <div className="">Total Amount</div>
                                  <div>{formatPrice(Math.round(totalAmount(challanFormik.values?.items)))}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {challanFormik.values.remarks && <div className="add-box">
                            <div className='fs-5 fw-bold'>
                              Remarks
                            </div>
                            <p className="mb-0">{challanFormik.values?.remarks}</p>
                          </div>}

                          <div className='mt-3'>
                            <div className='fs-5 fw-bold'>
                              Photos
                            </div>
                            <div className="row row-cols-1 row-cols-md-4 mt-3">
                              {imageArray.map((ele, index) => {
                                if (challanFormik.values[ele]) return <div className='p-1'> <div key={index} className='d-flex justify-content-center align-items-center border m-1 cursor-pointer' data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} style={{ height: "80px" }} onClick={() => { setSelectImage(S3_URL + challanFormik.values[ele]) }} > <img src={S3_URL + challanFormik.values[ele]} alt="" className='img-fluid w-100 h-100' /></div>
                                  <div className='text-center'>
                                    {getFileName(ele)}
                                  </div>
                                </div>
                                return null;
                              })}
                              {
                                challanFormik.values?.otherFiles?.map((ele, index) => {

                                  if (ele.image) return <div className='p-1'>
                                    <div key={"asd" + index} className='d-flex justify-content-center align-items-center border m-1 cursor-pointer' data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} style={{ height: "80px" }} onClick={() => { setSelectImage(S3_URL + ele.image) }} > <img src={S3_URL + ele.image} alt="" className='img-fluid w-100 h-100' /></div>
                                    <div className='text-center'>
                                      {ele.description || ""}
                                    </div>
                                  </div>
                                  return null;
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {challanFormik.values?.purchaseOrder && <>
        <ItemsAddModal formik={itemFormik} modalId={updateItemModalId} addLoader={addItemLoader} type="challan" removeItem={removeItem} selectItem={selectedItem} vendorId={challanFormik?.values?.purchaseOrder?.vendorId} />
        <ItemsAddModal formik={itemFormik} modalId={addItemModalId} addLoader={addItemLoader} type="challan" vendorId={challanFormik?.values?.purchaseOrder?.vendorId} />
      </>}
      <ImageViewModal imgUrl={selectImage} modalId={viewImageModalId} />
      <button className='d-none' id="submitModalBtn" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={handleChallanSubmit}
        message={<>Are you sure you want to <br /> Submit ?</>}
      />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default AddChallan