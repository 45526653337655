import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import Mainroute from './routes/Mainroute';
import { useUserContext } from './context/userContext';
import { getProfileApi } from './utils/ApiEndpointFunctions';
import { removeToken } from './utils/HelperFuncitons';

function App() {
  const token = localStorage.getItem("token")
  const { setUserDetails } = useUserContext();

  const getUserDetails = async () => {
    try {
      const res = await getProfileApi();
      if (res.status === 200) {
        setUserDetails(res?.data.data)
      } else {
        removeToken();
      }
    } catch (error) {
      removeToken();
    }
  }

  useEffect(() => {
    if (token) {
      getUserDetails()
    }
  }, [token])
  return (
    <div className="App">
      <Mainroute />
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}

export default App;
