import React, { useEffect, useState } from "react";
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import { getProfileApi, getSitesList, switchSite } from '../../utils/ApiEndpointFunctions';

import { toast } from 'react-hot-toast'
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import StatusModal from "../../components/modal/StatusModal";
import { removeToken } from "../../utils/HelperFuncitons";

const ManageSites = () => {
  const { setUserDetails } = useUserContext();
  const [siteList, setSiteList] = useState([])
  const [loading, setLoading] = useState(true)
  const [siteId, setSitedId] = useState()
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"

  const getUserDetails = async () => {
    try {
      const res = await getProfileApi();
      if (res.status === 200) {
        setUserDetails(res?.data.data)
      } else {
        removeToken();
      }
    } catch (error) {
      removeToken();
    }
  }
  const getSites = async () => {
    try {
      const res = await getSitesList();
      if (res.status === 200) {
        // toast.success(res.data.message);
        setSiteList(res?.data?.data?.userSites)
        setLoading(false)
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getSites()
  }, [])

  const handleSwitchSite = async () => {
    try {
      const res = await switchSite({ _id: siteId })
      if (res.status === 200) {
        document.getElementById(confirmModalId).click()
        document.getElementById("statusModalBtn").click()
        getUserDetails()
        getSites()
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }



  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">Manage Sites</h3>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex">
                  <nav className="navbar bg-body-tertiary">
                    <form role="search" className="container-fluid">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                        <input type="text" className="form-control" placeholder="Search Sites" aria-label="Username" aria-describedby="basic-addon1"  />
                      </div>
                    </form>
                  </nav>

                </div>
              </div> */}
              {/* <!-- table --> */}

              <table className="table d-table mt-4">
                <thead>
                  <tr>
                    <th scope="col-2">SITE NAME</th>
                    <th scope="col-2">SITE Address</th>
                    <th scope="col-8">COMPANY NAME</th>
                    <th scope="col-2">STATUS</th>
                    {/* <th scope="col-2">ACTION</th> */}
                  </tr>
                </thead>
                {
                  loading ?
                    <tbody >
                      <tr>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-center">
                            <Spinwheel />
                          </div>
                        </td>
                      </tr>
                    </tbody> :
                    <>
                      <tbody>

                        {siteList?.length > 0 ? siteList?.map((ele, index) => {
                          return (
                            <tr key={index}>
                              <td className= {!ele?.isCurrent ? `text-primary text-decoration-underline cursor-pointer`: "cursor-default"} data-bs-toggle={!ele?.isCurrent ? "modal": ""} data-bs-target={!ele?.isCurrent ? `#${confirmModalId}` : ""} onClick={() => { setSitedId(ele?._id) }}>{ele?.siteData?.name}</td>
                              <td>{ele?.siteData?.addressId?.address1}</td>
                              <td>{ele?.siteData?.companyId?.name}</td>
                              <td><button className={`text-capitalize ${ele?.isCurrent ? "approve" : "d-none"}`} style={{ cursor: "default" }}>{ele?.isCurrent ? "Current" : "InActive"}</button></td>
                              {/* <td>
                                {!ele?.isCurrent && <div className="dropdown">
                                  <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                  <ul className="dropdown-menu">
                                    {!ele?.isCurrent && <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={() => { setSitedId(ele?._id) }}>Switch</li>}
                                  </ul>
                                </div>}
                              </td> */}
                            </tr>)
                        }) : <tr className='text-center'>
                          <td colSpan="12">
                            <h1>Data Not Found</h1>
                          </td>
                        </tr>}
                      </tbody></>}
              </table>
            </div>
          </div>
        </div>

        <ConfirmationModal modalId={confirmModalId} handleConfirm={handleSwitchSite} message={<>Are you sure want to <br /> Switch ?</>} title={<>Switch site</>}/>
        <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
          modalId={statusModalId}
          message={"Site Switched Successfully."}
        />

      </div>
    </>
  )
};

export default ManageSites;
