import React, { useEffect, useState } from 'react'
import { approveOrRejectApi, getVoucherByIdApi, getVoucherRemainingAmountApi,downloadVoucherPdf } from '../../utils/ApiEndpointFunctions'
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import moment from 'moment';
import { S3_URL } from '../../utils/BaseUrl';
import { formatPrice } from '../../utils/HelperFuncitons';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import StatusModal from '../../components/modal/StatusModal';
import { useLocation } from 'react-router-dom';

function ViewVoucher() {
  const location = useLocation();
  const params = useParams();
  const { userDetails } = useUserContext();
  const { id } = params;
  const [voucherDetaits, setVoucherDetaits] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModalID"
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [confirmModalMsg, setConfirmModalMsg] = useState(<>Are you sure you want to <br /> Submit ?</>)
  const [isSubmitvoucher, setIsSubmitvoucher] = useState(false)
  const [okbtn, setOkbtn] = useState("")
  const [submitLoader, setSubmitLoader] = useState(false)
  const [download,setDownload] = useState(false)



  const getVoucherById = async () => {
    try {
      const res = await getVoucherByIdApi(id)
      if (res.status === 200) {
        const voucher = res.data?.data?.Voucher
        
        setVoucherDetaits(voucher);
      }
    } catch (error) {
      console.log(error)
      toast.error("Something Went Wrong")
    }

  }


  const handleVoucherApprove = async () => {
    try {
      
      const res = await approveOrRejectApi({ id, status:"approved" })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(`Voucher ${voucherDetaits?.voucherNumber} is successfully approved.`)
        document.getElementById("statusModalBtn").click()
        if(id){
          getVoucherById()
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }
  const checkValidation = async () => {
    try {
      setSubmitLoader(true)
      const res = await getVoucherRemainingAmountApi({
        challanOrPONumber: voucherDetaits?.challanNumber,
        expenseType: voucherDetaits?.expenseType,
        amount: voucherDetaits?.amount
      })

      if (res?.status == 200) {
        if (res?.data?.data?.status == 1) {
          setIsSubmitvoucher(true)
        } else {
          setOkbtn("no")
        }
        setConfirmModalMsg(<span className='p-3'>{res?.data?.message}</span>)
        setSubmitLoader(false)
      }

      document.getElementById("submitModalBtn1").click()
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")

      }
      setSubmitLoader(false)
    }
  }

  const handleConfirmation = ()=>{
    if(isSubmitvoucher){
      handleVoucherApprove() 
    }else{
      document.getElementById(confirmModalId).click()
    }
  }

  useEffect(() => {
    if (id) {
      getVoucherById()
    }
  }, [])

  const getDownloadVoucher =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadVoucherPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', voucherDetaits?.voucherNumber ? `${voucherDetaits.voucherNumber}.pdf` : 'voucher.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state}})} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
              <div className="row d-flex justify-content-center">
                <div className="col-xxl-11 border border-2 p-5 col-lg-10 col-md-12">
                  <div className="">
                    <div>
                      {/* <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Preview</div>
                          </div> */}
                      <div>
                        <div className='fs-5 fw-bold'>
                          {voucherDetaits?.name}
                        </div>

                        <div className='mt-3 d-flex justify-content-between form-details'>
                          <label>Voucher number</label>
                          <div>{voucherDetaits?.voucherNumber}</div>
                        </div>
                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Created date</label>
                          <div>{moment(voucherDetaits?.createdAt).format("DD/MM/YYYY")}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>PO/Challan number</label>
                          <div>{voucherDetaits?.challanNumber}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Status</label>
                          <div className='text-capitalize'>{voucherDetaits?.status}</div>
                        </div>
                        <hr />

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Name</label>
                          <div>{voucherDetaits?.name}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Issuer type</label>
                          <div className='text-capitalize'>{voucherDetaits?.vendorType}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Voucher type</label>
                          <div className={voucherDetaits?.voucherType?.toLowerCase() === "upi" ? "text-uppercase" : "text-capitalize"}>{voucherDetaits?.voucherType}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Expenses type</label>
                          <div className='text-capitalize'>{voucherDetaits?.expenseType}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Amount</label>
                          <div>{formatPrice(Number(voucherDetaits?.amount))}</div>
                        </div>

                        <div className='mt-3 d-flex justify-content-between  form-details'>
                          <label>Remarks</label>
                          <div>{voucherDetaits?.remarks}</div>
                        </div>
                        <hr />

                        {voucherDetaits?.voucherType?.toLowerCase() !== "cash" ? <>
                        
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Bank details</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Bank name</label>
                            <div>{voucherDetaits?.bankName}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Cheque number</label>
                            <div>{voucherDetaits?.chequeNumber}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Cheque date</label>
                            <div>{voucherDetaits?.chequeDate ? moment(voucherDetaits?.chequeDate).format("DD/MM/YYYY") : "-"}</div>
                          </div>
                        </>
                          :
                          <>
                            <div className="d-flex justify-content-between mb-1">
                              <div className='fs-6 fw-bold'>Signature of recipient</div>
                            </div>

                            <div className="border border-2 d-flex justify-content-center align-items-center">
                              <img src={S3_URL + voucherDetaits?.signature} alt="" className='img-fluid' style={{maxHeight:"300px"}} />
                            </div>
                          </>}
                      </div>
                      <hr />
                      <div className="d-flex justify-contend-start gap-2">
                        {userDetails?.roleId?.permission.includes("voucherApprove") && voucherDetaits?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3">
                          <div className="btn btn-primary" disabled={submitLoader} onClick={(e) => { setOkbtn(""); checkValidation(e) }}>{submitLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Approve"}</div>
                        </div>}
                    {userDetails?.roleId?.permission.includes("voucherUpdate") && voucherDetaits?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/voucher/add/${id}`) }} > Edit</button></div>}
                    <div className="d-flex justify-contend-start gap-2">
                          {voucherDetaits?.status?.toLowerCase() === "approved" && <div className="d-flex justify-content-between gap-2">
                            <button className="btn create-order text-white" disabled={download} onClick={() => !download && id && getDownloadVoucher(id)}>Download</button>
                          </div>}
                        </div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} />
        <ConfirmationModal modalId={confirmModalId} handleConfirm={handleConfirmation} message={confirmModalMsg} okbtn={okbtn} />
        <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
          modalId={statusModalId}
          message={confirmationMsg}
          handleClose={()=>navigate("/voucher")}
        />
      </div>
    </>
  )
}

export default ViewVoucher