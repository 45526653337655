import React, { useEffect, useState } from 'react'
import Spinwheel from '../common/Spinwheel'
import DatePicker from "react-datepicker";
import { getVoucherSuggestionNameApi, getvendorsApi } from '../../utils/ApiEndpointFunctions';
import { debounce } from 'lodash';
// import AsyncSelect from 'react-select/async';
import Select from 'react-select';

function VoucherFilter(props) {
  const { modalId, formik, clickHandler } = props
  const [VendorSuggestion, setVendorSuggestion] = useState([])
  // const filterSuggestionName = async (inputValue,cb) => {
  //   try {
     
  //       const res = await getVoucherSuggestionNameApi("vendor", inputValue)
  //       if (res.status === 200) {
  //         let fetchedItem = res.data?.data?.suggestion?.map(ele => ({ label: ele, value: ele }))
  //         cb(fetchedItem);
  //       }
      
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  // const vendorOnChange = debounce(filterSuggestionName, 1000);

  // const vendorOptions = (inputValue, callback) => {
  //   vendorOnChange(inputValue, callback);
  // }

  const filterSuggestionName = async (inputValue) => {
    try {
      const res = await getVoucherSuggestionNameApi(formik?.values?.vendorType, inputValue)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.suggestion?.map(ele => ({ label: ele, value: ele }))
        setVendorSuggestion(fetchedItem)
      }
      else {
        setVendorSuggestion([])
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleVendorSelect = (e) => {
   
    formik.setFieldValue("name", e.label);
  }
  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };

  const handleApplyFilter = () => {
    const errors = Object.keys(formik?.errors)
    if(!errors.length){
      clickHandler()
    }
  }

  useEffect(() => {
      filterSuggestionName()
  },[formik?.values?.vendorType])
  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Filter</h5>
                </div>
                <form className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="challanNumber" className="form-label">Challan/ PO number</label>
                    <input type="text" className="form-control" id="challanNumber" placeholder="Enter " name='challanNumber' value={formik?.values?.challanNumber} onChange={(e)=>formik?.setFieldValue("challanNumber",e?.target?.value?.toUpperCase())} onBlur={formik?.handleBlur} />

                  </div>

                  <div className="ed-box">
                    <label htmlFor="">Vendor type</label>
                    <select className="form-select" name='vendorType' aria-label="Default select example" value={formik?.values?.vendorType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="vendor">Vendor</option>
                      <option value="subcontractor">Subcontractor</option>
                      <option value="labour">Labour</option>
                      <option value="staff">Staff</option>
                      <option value="misc">Misc</option>
                    </select>
                  </div>

                  <div className="ed-box">
                    <label htmlFor="">Name</label>
                    {/* <AsyncSelect className='mb-3 col' placeholder="Select " cacheOptions value={formik.values?.name ? { label: formik.values?.name }: null} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e,) }} /> */}
                    <Select className='mb-3 col' placeholder="Select " value={formik.values?.name ? { label: formik.values?.name }: null} options={VendorSuggestion} onChange={(e) => { handleVendorSelect(e,) }} />

                  </div>
                  <div className="ed-box">
                  <label htmlFor="">Date</label>
                    <select className="form-select" name='dateType' aria-label="Default select example" value={formik?.values?.dateType} onChange={e => {formik?.handleChange(e); formik.setFieldValue('to',""); formik.setFieldValue("from","")}} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="0">1 Day</option>
                      <option value="1">1 Week</option>
                      <option value="2">1 Month</option>
                      <option value="3">Custom</option>
                    </select>
                    {formik?.values?.dateType === "3" && <><label htmlFor="date" className="form-label">Custom Range</label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className='form-select cursor-pointer'
                      startDate={formik.values?.to ? formik.values?.to : null}
                      endDate={formik.values?.from ? formik.values?.from : null}
                      maxDate={new Date(new Date(formik.values?.to ? formik.values?.to : new Date()).setTime(new Date(formik.values?.to ? formik.values?.to : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                      onChange={(update) => {
                        handleSelectDate(update[0], "to")
                        handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='from - to'
                      showDisabledMonthNavigation
                    />
                    {formik?.touched?.dateType && (formik?.errors?.to || formik?.errors?.from) ? (<div className='req-error'>{formik?.errors?.to || formik?.errors?.from}</div>) : null}
                    </>}
                  </div>

                  <div className="ed-box">
                    <label htmlFor="">Expenses type</label>
                    <select className="form-select" name='expenseType' aria-label="Default select example" value={formik?.values?.expenseType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="inward">Inward</option>
                      <option value="outward">Outward</option>
                    </select>
                  </div>
                  <div className="ed-box">
                    <label htmlFor="">Voucher type</label>
                    <select className="form-select" name='voucherType' aria-label="Default select example" value={formik?.values?.voucherType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="cash">Cash</option>
                      <option value="cheque">Cheque</option>
                      <option value="draft">Draft</option>
                    </select>

                  </div>
                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <div className="save" onClick={handleApplyFilter}>
                      Apply
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VoucherFilter