import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatPrice } from '../../utils/HelperFuncitons';

const ItemReport = ({ report, getTotalValue, getBalance, groupVendorPOItems, filterFormik }) => {
    return (
        <>
            <div className='mt-4'>
                {report?.map((ele, index) => <table key={index} className="table table-bordered table-responsive mt-4">
                    <thead>
                        <tr>
                            <th scope='col' colspan="12" className=''>{ele?.vendorDetails?.name}</th>
                        </tr>
                        <tr>
                            <th scope='col' >PO Number</th>
                            <th scope='col' >PO Date</th>
                            <th scope='col' >PO Amount</th>
                            <th scope='col' >Challan No</th>
                            <th scope='col' >Challan Date</th>
                            <th scope='col' >Amount</th>
                            <th scope='col' >Vouchers Number</th>
                            <th scope='col' >Voucher Date</th>
                            <th scope='col' >Voucher Amount</th>
                            <th scope='col' >Item</th>
                            <th scope='col' >PO Item Rate</th>
                            <th scope='col' >Challan Item Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ele?.vendorPo?.map((po) => {
                            const vouchers = po?.combinedVouchers || [];
                            const itemsArr = po?.combinedItems?.length ? groupVendorPOItems(po?.combinedItems) : [];
                            const maxCount = Math.max(vouchers.length, itemsArr.length, 1);

                            return Array.from({ length: maxCount }).map((_, index) => {
                                const voucher = vouchers[index];
                                const item = itemsArr[index];
                                return (
                                    <tr key={index}>
                                        <td>{(index === 0 || voucher?.challanNumber?.includes("PO")) && <Link to={`/purchase/view/${po?._id}`} state={{ path: "/report", filterValue: filterFormik?.values }} >{po?.poNumber}</Link>}</td>
                                        <td>{index === 0 && moment(po?.createDate).format("DD/MM/YYYY")}</td>
                                        <td className='wordBreake'>{index === 0 && formatPrice(po?.amount)}</td>
                                        <td>{(index === 0 || voucher?.challanNumber?.includes("CH")) && <Link to={`/challan/view/${po?.challan?._id}`} state={{ path: "/report", filterValue: filterFormik?.values }} >{po?.challan?.challanNumber}</Link>}</td>
                                        <td>{index === 0 && po?.challan?.createdAt && moment(po?.challan?.createdAt).format("DD/MM/YYYY")}</td>
                                        <td className='wordBreake'>{index === 0 && po?.challan?.amount && formatPrice(po?.challan?.amount)}</td>
                                        <td>{voucher && <Link to={`/voucher/view/${voucher._id}`} state={{ path: "/report", filterValue: filterFormik?.values }} >{voucher?.voucherNumber}</Link>}</td>
                                        <td>{voucher && moment(voucher?.createDate).format("DD/MM/YYYY")}</td>
                                        <td>{voucher && formatPrice(voucher?.amount)}</td>
                                        <td>{item && item?.name}</td>
                                        <td className='wordBreake'>{Boolean(item && item?.unitPrice) && formatPrice(item?.unitPrice)}</td>
                                        <td className='wordBreake'>{Boolean(item && item?.challanUnitPrice) && formatPrice(item?.challanUnitPrice)}</td>
                                    </tr>
                                );
                            });
                        })}

                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Sub total</td>
                            <td></td>
                            <td className='wordBreake'>{formatPrice(getTotalValue(ele?.vendorPo, "po"))}</td>
                            <td> Challan value</td>
                            <td></td>
                            <td className='wordBreake'>{formatPrice(getTotalValue(ele?.vendorPo, "challan"))}</td>
                            <td>Voucher value</td>
                            <td></td>
                            <td className='wordBreake'>{formatPrice(getTotalValue(ele?.vendorPo, "voucher"))}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan="12">
                                <div className='wordBreake'><span className='fw-bold'>Balance:</span>{formatPrice(Math.max(getBalance(getTotalValue(ele?.vendorPo, "challan"), getTotalValue(ele?.vendorPo, "voucher")), 0))}</div>
                                <div className='wordBreake'><span className='fw-bold'>Advance:</span> {formatPrice(Math.abs(Math.min(getBalance(getTotalValue(ele?.vendorPo, "challan"), getTotalValue(ele?.vendorPo, "voucher")), 0)))}</div>
                            </td>
                        </tr>
                    </tfoot>
                </table>)}
            </div>
        </>
    )
}

export default ItemReport