import { debounce } from "lodash";
import { getItemSuggestionApi, getItemsApi,getUnitTypeApi } from "../../utils/ApiEndpointFunctions";
import Spinwheel from "../common/Spinwheel"
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { formatPrice } from "../../utils/HelperFuncitons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
// import Autocomplete from "react-autocomplete";


function ItemsAddModal(props) {
  const { formik, addLoader, modalId, type, removeItem, selectItem, vendorId } = props;
  const location = useLocation()
  const [isAddBtn, setIsAddBtn] = useState(true)
  const [units,setUnits] = useState([])

  const paths = location.pathname?.split("/")

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await (paths.includes('challan') ? getItemsApi(inputValue, "", "", vendorId) : getItemSuggestionApi(inputValue))
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }

const getUnitType = async()=>{
  try {
    const res = await getUnitTypeApi()
    if(res?.status==200 && res?.data?.data){
      let getUnits = res?.data?.data?.unitTypes?.map(ele => ({ label: ele.name, value: ele?.name }))
       setUnits([...getUnits,{label: "Other", value: "Other"}])
    }
  } catch (error) {
    console.log("failed to get unit type");
    setUnits([])
  }
}



const handleUnitType =(e)=>{
  // console.log("handleUnitType",e);
  formik.setFieldValue("unitType",e?.value)
}


  const handleItemSelect = (e) => {
    formik.setFieldValue("isDisabled", false)
    if (selectItem) {
      if (e.__isNew__) {
        formik.setFieldValue("name", e.value);
      } else {
        formik.setFieldValue("name", e.value?.name);
      }
    } else {
      if (e.__isNew__) {
        formik.resetForm();
        formik.setFieldValue("isDisabled", false)
        formik.setFieldValue("name", e.value);
        formik.setFieldValue("__isNew__", e.__isNew__);
        //  for add item default value 
        formik.setFieldValue('unitPrice', '')
        formik.setFieldValue('totalQuantity','')
      } else {
        formik.setFieldValue("__isNew__", false);
        formik.setFieldValue("name", e.value?.name);
        formik.setFieldValue("localName", e.value?.localName);
        formik.setFieldValue("description", e.value?.description || null);
        formik.setFieldValue('hsnCode', e.value?.hsnCode ? e.value?.hsnCode : "")
        //  for add item default value 
        formik.setFieldValue('unitPrice', '')
        formik.setFieldValue('totalQuantity','')
        formik.setFieldValue('unitType', e.value?.unitType || formik.initialValues.unitType)
        formik.setFieldValue('tax', e.value?.tax || formik.initialValues.tax)
        formik.setFieldValue('_id', e.value?._id || null)
      }
    }
  }

  // const filterItemSuggestion = async (inputValue) => {
  //   try {
  //     if (inputValue) {
  //       const res = await getItemSuggestionApi(inputValue)
  //       if (res.status === 200) {
  //         let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
  //         setItemSuggestion(fetchedItem)
  //       }
  //     }else{
  //       setItemSuggestion([])
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };
  // const itemSuggstionOnChange = debounce(filterItemSuggestion, 1000)

  // const handleSelectItemSuggestion = (label, value) => {
  //   if (value) {
  //     formik.setFieldValue("name", value.value.name)
  //     formik.setFieldValue("localName", value.value.localName)
  //     formik.setFieldValue("description", value.value.description)
  //     }
  // }

  useEffect(()=>{
    if(!selectItem){
      formik.setFieldValue('unitPrice', '')
      formik.setFieldValue('totalQuantity', '')
    }
    getUnitType()
  },[selectItem,isAddBtn])
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              {/* <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => setIsAddBtn(true)}
              ><i className="bi bi-x-lg" ></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>{selectItem ? "Edit" : "Add"} item details</h5>
                </div>
                <form onSubmit={formik?.handleSubmit} className="my-fx">

                  {/* {(type === "challan" || type === "addChallan") ?  */}
                  <div className="d-flex justify-content-center align-items-center mb-3 ">
                    {isAddBtn ? (
                      <AsyncCreatableSelect
                        className="col"
                        placeholder="Select..."
                        cacheOptions
                        loadOptions={itemsOptions}
                        defaultOptions
                        value={formik?.values?.name ? { label: formik?.values?.name, value: formik.values } : null}
                        onChange={(e) => { handleItemSelect(e) }}
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                          if (inputValue?.trim?.() && paths.includes('purchase')) {
                            return selectOptions.findIndex(option => option.label === inputValue) === -1; 
                          } else {
                            return false; 
                          }
                        }}
                        isDisabled={paths.includes('challan') && selectItem}
                      />

                    ) : (
                      <input
                        className="form-control col"
                        placeholder="Enter..."
                        value={formik?.values?.name || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="name"
                      />
                    )}
                    <span className=" ms-2" style={{ cursor: "pointer" }} onClick={() => { formik?.resetForm(); formik.setFieldValue("isDisabled", !Boolean(isAddBtn)); setIsAddBtn(value => (!value)) }} >
                      {!selectItem && <span>
                        {paths.includes('purchase') && (isAddBtn ? <>
                          <i className="form-input bi bi-plus-square " /><span>{" "}Add Item</span>
                        </>
                          :
                          <>
                            <i class="form-input bi bi-x-circle"></i><span>{" "}Cancel</span>
                          </>)
                        }
                      </span>}
                    </span>
                  </div>
                  {/* :
                    <div className="ed-box">
                      <label htmlFor="name" >Item name <span className='req-error'>*</span></label>
                      <input type="text" className="form-control" id="name" placeholder="Enter name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                    </div> */}
                  {/* } */}

                  {/* <div className="ed-box">
                    <label htmlFor="localName" >Item Name <span className='req-error'>*</span></label>
                    <Autocomplete
                      getItemValue={(item) => item.label}
                      wrapperStyle={{
                        display: 'block'
                      }}
                      items={itemSuggestion}
                      renderItem={(item, isHighlighted) =>
                        <div className='cursor-pointer'>
                          <p
                            className={`suggestion-list-item`}
                            style={{ backgroundColor: isHighlighted ? '#B2D4FF' : 'transparent' }}
                          >
                            {item.label || ""}
                          </p>
                        </div>
                      }
                      renderInput={(props) =>
                        <input className='form-control' placeholder='Enter Name' {...props} />
                      }
                      value={formik.values.name}
                      onChange={(e) => { formik.setFieldValue("name", e.target.value); itemSuggstionOnChange(e.target.value) }}
                      onSelect={(label, val) => handleSelectItemSuggestion(label, val)}
                      renderMenu={(items, value, style) =>
                        <div className='suggestion-list' children={items} />
                      }
                    />

                    {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                  </div> */}

                  <div className="ed-box">
                    <label htmlFor="localName" >Item Local name <span className='req-error'>*</span></label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="localName" placeholder="Enter local name" name='localName' value={formik?.values?.localName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.localName && formik?.errors?.localName ? (<div className='req-error'>{formik?.errors?.localName}</div>) : null}
                  </div>

                  <div className="ed-box">
                    <label htmlFor="description" className="form-label">Description <span className='req-error'>*</span></label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="description" placeholder="Enter description" name='description' value={formik?.values?.description} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.description && formik?.errors?.description ? (<div className='req-error'>{formik?.errors?.description}</div>) : null}
                  </div>

                  {/* {paths.includes('challan') &&  <div className="ed-box ">
                    <label htmlFor="totalQuantity" className="form-label">Total quantity  <span className='req-error'>*</span></label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="totalQuantity" placeholder="Enter total quantity" name='totalQuantity' value={formik?.values?.totalQuantity} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.totalQuantity && formik?.errors?.totalQuantity ? (<div className='req-error'>{formik?.errors?.totalQuantity}</div>) : null}
                  </div>} */}
                  <div className={`row row-cols-2"}`}>
                    {/* {paths.includes('challan') && <div className="ed-box">
                      <label htmlFor="unitPrice" className="form-label">Unit price <span className='req-error'>*</span> </label>
                      <input type="text" disabled={formik.values.isDisabled} className="form-control" id="unitPrice" placeholder="Enter unit price" name='unitPrice' value={formik?.values?.unitPrice} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.unitPrice && formik?.errors?.unitPrice ? (<div className='req-error'>{formik?.errors?.unitPrice}</div>) : null}
                    </div>} */}
                    <div className="ed-box">
                      <label htmlFor="unitType" className="form-label">Unit type </label>
                      {/* <input type="text" className="form-control" id="unitType" placeholder="Enter unit type" name='unitType' value={formik?.values?.unitType} onChange={formik?.handleChange} onBlur={formik?.handleBlur} /> */}

                      {/* <select disabled={formik.values.isDisabled} className="form-select" aria-label="Default select example" name='unitType' value={formik?.values?.unitType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                        <option value="pieces">Pieces</option>
                        <option value="kg">Kg</option>
                        <option value="ton">Ton</option>
                        <option value="quintal">Quintal</option>
                      </select> */}



                      <Select style={{ cursor: "pointer !important" }} isDisabled={formik.values.isDisabled} className="w-100" aria-label="Default select example" name='unitType' placeholder="Select unit type" value={{ label: formik?.values?.unitType, value: formik?.values?.unitType }} isSearchable={true} options={units} onChange={(e) => { handleUnitType(e) }} />
                      {formik?.touched?.unitType && formik?.errors?.unitType ? (<div className='req-error'>{formik?.errors?.unitType}</div>) : null}
                    </div>
                   {formik?.values?.unitType?.toLowerCase()=="other" && <div className="ed-box">
                      <label htmlFor="otherUnit" className="form-label">Other unit</label>
                      <input
                        className="form-control col"
                        placeholder="Enter..."
                        value={formik?.values?.otherUnit || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="otherUnit"
                      />
                      {formik?.touched?.otherUnit && formik?.errors?.otherUnit ? (<div className='req-error'>{formik?.errors?.otherUnit}</div>) : null}
                    </div>} 


                  </div>

                  <div className="ed-box">
                    <label htmlFor="hsnCode" className="form-label">HSN code <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="hsnCode" placeholder="Enter HSN code" name='hsnCode' value={formik?.values?.hsnCode}
                      onChange={(e) => {
                        e.target.value =  e?.target?.value?.toUpperCase()?.replace(/[^a-zA-Z0-9 ]/g, "");
                        formik?.handleChange(e);
                      }}
                      onBlur={formik?.handleBlur} />
                    {formik?.touched?.hsnCode && formik?.errors?.hsnCode ? (<div className='req-error'>{formik?.errors?.hsnCode}</div>) : null}
                  </div>

                  <div className="ed-box">
                    <label htmlFor="tax" className="form-label">GST (%)</label>
                    {/* <input type="text" className="form-control" id="tax" placeholder="Enter gst" name='tax' value={formik?.values?.tax} onChange={formik?.handleChange} onBlur={formik?.handleBlur} /> */}

                    <select className="form-select" disabled={formik.values.isDisabled} name='tax' aria-label="Default select example" value={formik?.values?.tax} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="0">0</option>
                      <option value="5">5</option>
                      <option value="12">12</option>
                      <option value="18">18</option>
                      <option value="28">28</option>
                    </select>

                    {formik?.touched?.tax && formik?.errors?.tax ? (<div className='req-error'>{formik?.errors?.tax}</div>) : null}
                  </div>

                  {type === "challan" && <>
                    <div className="mb-3 d-flex justify-content-between">
                      <label htmlFor="tax" className="form-label">Item breakage</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" disabled={formik.values.isDisabled} type="checkbox" role="switch" id="flexSwitchCheckChecked" name="isBreakage" checked={formik.values.isBreakage} onChange={(e) =>{ formik.setFieldValue("isBreakage", e.target.checked); if(!e?.target?.checked){formik.setFieldValue("breakage","")}}} />
                      </div>
                    </div>

                    <div className="mb-3">
                      <textarea className="form-control" id="exampleFormControlTextarea1" disabled={!formik.values.isBreakage} rows="3" value={formik?.values?.breakage} name="breakage" onChange={formik?.handleChange}></textarea>
                    </div>
                  </>}

                  {/* <div className="mb-3">

                    <div className="price-card p-2 px-3 d-flex justify-content-between">
                      <div className="fw-bold">Total cost with GST</div>
                      <div className="fw-bold">{formatPrice((Number(formik?.values?.unitPrice) * Number(formik?.values?.totalQuantity)) * (100 + Number(formik?.values?.tax))/100)}</div>          
                    </div>
                    

                  </div> */}
                  {/* <div className="tm1-box">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="dark mb-0">Total amount</p>
                      <p className="light mb-0">{formatPrice(((Number(formik?.values?.unitPrice) * Number(formik?.values?.totalQuantity)) * (100 + Number(formik?.values?.tax))) / 100)}</p>
                    </div>
                  </div> */}

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button type="submit" disabled={addLoader} className="save"
                      onClick={() => setIsAddBtn(true)}
                    >
                      {addLoader ? <Spinwheel sizeclassName={"spin-small"} /> : "Save"}
                    </button>

                    {type === "challan" && selectItem && selectItem === formik?.values?._id && <div className="btn btn-secondary ms-3" onClick={() => { removeItem(formik?.values?._id) }}>
                      Remove item
                    </div>}
                    
                    {type === "po" && selectItem &&  selectItem === formik?.values?._id && <div className="btn btn-secondary ms-3" onClick={() => { removeItem(formik?.values?._id) }}>
                      Remove item
                    </div>}
                  </div>

                  {/* <div className="d-flex mt-4 justify-content-end align-items-center">
                  {type === "challan" && formik?.values?._id && <div className="remove" onClick={() => { removeItem(formik?.values?._id) }}>
                  Remove this item
                    </div>}
                            <button className="remove">Remove this item</button>
                            <button className="save">Save</button>
                        </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ItemsAddModal