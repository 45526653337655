import React from "react";
import { createContext, useContext, useState } from "react";

export const userContext = createContext({
    userDetails: {}

});

export function UserContextProvider({ children }) {
    const [userDetails, setUserDetails] = useState({});
    

    return (
        <userContext.Provider value={{ userDetails, setUserDetails }}>
            {children}
        </userContext.Provider>
    );
}

export function useUserContext() {
    return  useContext(userContext);
}