// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRpnB8M5o4f6a6ilOLgwRM0nydbB-51xo",
  authDomain: "manage-my-site.firebaseapp.com",
  projectId: "manage-my-site",
  storageBucket: "manage-my-site.appspot.com",
  messagingSenderId: "789525121053",
  appId: "1:789525121053:web:6ca479e0359201c7f0def4",
  measurementId: "G-83G6H8K5P1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);