import React from 'react'
import Spinwheel from '../common/Spinwheel'
import DatePicker from "react-datepicker";
import { getvendorsApi } from '../../utils/ApiEndpointFunctions';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';

function CashbookFilter(props) {
  const { modalId, formik, clickHandler } = props

  const filtervendors = async (inputValue, cb) => {
    try {
      const res = await getvendorsApi(inputValue)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const vendorOnChange = debounce(filtervendors, 1000);

  const vendorOptions = (inputValue, callback) => {
    vendorOnChange(inputValue, callback);
  }

  const handleVendorSelect = (e) => {
   
    formik.setFieldValue("name", e.value?.name);
  }
  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };
  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Filter</h5>
                </div>
                <form className="my-fx">

                  {/* <div className="ed-box">
                    <label htmlFor="challanNumber" className="form-label">Challan/ PO Number</label>
                    <input type="text" className="form-control" id="challanNumber" placeholder="Enter " name='challanNumber' value={formik?.values?.challanNumber} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />

                  </div> */}

                  {/* <div className="ed-box">
                    <label htmlFor="">Vendor type</label>
                    <select className="form-select" name='vendorType' aria-label="Default select example" value={formik?.values?.vendorType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="vendor">Vendor</option>
                      <option value="subcontractor">Subcontractor</option>
                      <option value="labour">Labour</option>
                      <option value="staff">Staff</option>
                      <option value="misc">Misc</option>
                    </select>
                  </div>

                  <div className="ed-box">
                    <label htmlFor="">Name</label>
                    <AsyncSelect className='mb-3 col' placeholder="Select " cacheOptions value={formik.values?.name ? { label: formik.values?.name }: null} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e,) }} />

                  </div> */}
                  <div className="ed-box">
                    <label htmlFor="date" className="form-label">Date</label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className='form-select cursor-pointer'
                      startDate={formik.values?.to ? formik.values?.to : null}
                      endDate={formik.values?.from ? formik.values?.from : null}
                      maxDate={new Date(new Date(formik.values?.to ? formik.values?.to : new Date()).setTime(new Date(formik.values?.to ? formik.values?.to : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                      onChange={(update) => {
                        handleSelectDate(update[0], "to")
                        handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='date'
                      showDisabledMonthNavigation
                    />
                  </div>

                  {/* <div className="ed-box">
                    <label htmlFor="">Expenses type</label>
                    <select className="form-select" name='expenseType' aria-label="Default select example" value={formik?.values?.expenseType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="inward">Inward</option>
                      <option value="outward">Outward</option>
                    </select>
                  </div>
                  <div className="form-details">
                    <label htmlFor="">Voucher type</label>
                    <select className="form-select" name='voucherType' aria-label="Default select example" value={formik?.values?.voucherType} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="cash">Cash</option>
                      <option value="cheque">Cheque</option>
                      <option value="draft">Draft</option>
                    </select>

                  </div> */}
                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <div className="save" onClick={(e) => { clickHandler(); document.getElementById(modalId).click() }}>
                      Apply
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CashbookFilter