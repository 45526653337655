import React, { useEffect, useState } from 'react'
import OtpInput from "otp-input-react";
import { OtpValidationSchema, OtpverificationInitial } from '../../utils/Validation';
import { useFormik } from 'formik';
import Spinwheel from '../../components/common/Spinwheel';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfileApi, isExistApi, signInApi } from '../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { removeToken } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from '../../utils/firebase';
import Logo from '../../components/common/Logo';

function Otpverification() {
  const [loading, setLoading] = useState(false);
  const [resendLoading,setResendLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [resendTime,setResendTime] = useState(30);
  const { setUserDetails } = useUserContext();

  const getUserDetails = async () => {
    const { data } = await getProfileApi();
    if (data && data.data) {
      setUserDetails(data.data)
    } else {
      removeToken();
    }
  }
  

  function onCaptchVerify() {

    if (window && !window.recaptchaVerifier) {

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {

          },
          'expired-callback': () => {
            toast.error("otp otp expired")
          }
        }
      );
    }
  }

  async function resendOtp() {

    if (!location?.state?.phoneNumber) return;
    try {
      setResendLoading(true);
      onCaptchVerify();
      formik.resetForm();
      const appVerifier = window.recaptchaVerifier;
      

      const res = await isExistApi({ phone: location?.state?.phoneNumber });

      if (res.status === 200) {
        signInWithPhoneNumber(auth, location?.state?.phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setResendLoading(false);
            navigate("/otp-verification", { state: { phoneNumber: location?.state?.phoneNumber } })
            toast.success("OTP sended successfully!");
            setResendTime(30);
          })
          .catch((error) => {
            setResendLoading(false);
            setResendTime(30);
            let errorMsg = { ...error }
            if (errorMsg.code === 'auth/invalid-phone-number') {
              toast.error('Invalid Phone Number!!')
            } else if (errorMsg.code === "auth/too-many-requests") {
              toast.error('Too Many Attempts, Try Later!')
            } else {
              toast.error('Something Went Wrong!!')
            }
          });
      }
    } catch (error) {
      console.log(error)
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error(error?.message);
      setLoading(false);
      setResendTime(30);
    }
  }


  function handleSubmit(values) {
    try {
      setLoading(true);
      window.confirmationResult
        .confirm(values.otp)
        .then(async (res) => {
          const response = await signInApi({ token: res?._tokenResponse?.idToken })
          setLoading(false);
          toast.success(response.data?.message);
          localStorage.setItem("token", response.data?.token);
          getUserDetails();
          navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: OtpverificationInitial,
    onSubmit: handleSubmit,
    validationSchema: OtpValidationSchema
  })

  useEffect(() => {
    if(resendTime !== 0) {
      setTimeout(() => {
        setResendTime(resendTime - 1)
      }, 1000)
    }
  },[resendTime])


  return (
    <section className="login">
      <div className="container position-relative">
        <div className="msg-box">
          <p><i className="bi bi-check2"></i>You received a 6-digit OTP in your phone message box.</p>
        </div>
        <div className="centralize">
          <div className="login-box otp">
            {/* <div className="logo">
              <img src="/images/Group.png" alt="logo" />
            </div> */}
            <Logo width="100%" logoUrl="/Group.png" />
            <form className="mt-4 " onSubmit={formik.handleSubmit} >
              <label htmlFor="">Phone number</label>
              <input className="w-100 mb-3" type="text" disabled value={location?.state?.phoneNumber ? location?.state?.phoneNumber : ""} onChange={() => { }} placeholder="Enter registered phone number" />
              <label htmlFor="">One time password</label>
              <input
                className="w-100" type="text"
                value={formik.values.otp}
                name='otp'
                autoFocus
                onChange={(e) => { !isNaN(e?.target?.value) && e?.target?.value?.length<=6 &&  formik.setFieldValue("otp", e?.target?.value) }} placeholder="Enter OTP here" />
              <div className='mt-4'>
                {formik.touched.otp && formik.errors.otp ? (<div className='text-danger'>{formik.errors.otp}</div>) : null}
                <button type='submit' className="login-btn text-center">
                  {loading ? <Spinwheel sizeclassName="spin-small" /> : "Log in"}
                </button>
              </div>
            </form>
            <p className="mt-4">Didn’t received OTP yet? <span className='fw-bold'>{resendTime ? `${resendTime} seconds`:""} </span>  </p>
            {!resendTime && <button className="otp-btn"  disabled={resendTime} onClick={(e) => { e.preventDefault(); resendOtp(); }}> {resendLoading ? <Spinwheel sizeclassName="spin-small" /> : "Send OTP again"}</button>}
          </div>
        </div>
      </div>
    </section>
  )


  // return (
  //   <div className='d-flex align-items-center vh-100' >
  //     <div className="container">
  //       <div className="row align-items-center">
  //         <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">

  //           <div className="text-center">
  //             <img src="assets/img/illustrations/happiness.svg" alt="img" className="img-fluid" />
  //           </div>

  //         </div>
  //         <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">

  //           <h1 className="display-4  mb-3">
  //             Verify your OTP
  //           </h1>
  //           <br />
  //           <br />
  //           <p>We will send you a One Time Password
  //             on this mobile number.</p>
  //           <p>
  //             Send OTP to {location?.state?.phoneNumber}
  //           </p>
  //           <form action="" onSubmit={formik.handleSubmit} >

  //             <div>
  //               <OtpInput
  //                 value={formik.values.otp}
  //                 onChange={(e) => { formik.setFieldValue("otp", e) }}
  //                 OTPLength={6}
  //                 otpType="number"
  //                 disabled={false}
  //                 autoFocus
  //                 className="opt-container "
  //               ></OtpInput>

  //               {formik.touched.otp && formik.errors.otp ? (<div className='text-danger'>{formik.errors.otp}</div>) : null}

  //             </div>

  //             <div className='mt-3'>
  //               <button type='submit' className="btn btn-lg w-100 btn-primary mb-3">
  //                 {loading ? <Spinwheel sizeclassName="spin-small" /> : "Verify"}
  //               </button>
  //             </div>
  //           </form>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default Otpverification