import React from 'react'
import MainHeader from './mainHeader'
import MainNavbar from './navbar'
import MainSidebar from './mainSidebar'
import { Link, useNavigate } from 'react-router-dom';
// import Logo from "./"
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';

export default function DashboardTemplate({ children }) {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const { userDetails } = useUserContext();

  const handleLogout = () => {
    localStorage.removeItem("token");

    window.location.reload();
    navigate("/");
  }

  const path = location.pathname
  return (
    <>

      <div className="dashboard">

        <div className="fixed">
          {/* <!-- topnavbar --> */}
          {/* <MainHeader /> */}
          {/* <!-- navbar --> */}
          <MainNavbar />
        </div>

        <div className="d-flex">

          {/* <!-- side-nav --> */}
          <MainSidebar />
          {/* <!-- main-dash --> */}
          <div className="main-dash">
            {children}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalout1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body pe-0 ps-0 block-user">
              <div className="logout-icon">
                <i className="bi bi-box-arrow-left"></i>
              </div>
              <h1 className="mb-0">Log Out?</h1>
              <p>Are you sure you want to log out?</p>
            </div>
            <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
              <button
                type="button"
                className="btn-custom w-auto text-white"
                onClick={handleLogout}
              >
                LOG OUT
              </button>
              <button
                type="button"
                className="btn-custom w-auto text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
