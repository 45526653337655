import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { getItemSuggestionApi, getvendorsApi } from '../../utils/ApiEndpointFunctions';
import moment from 'moment';

function Filter(props) {
  const { modalId, formik, clickHandler } = props
  const [vendors, setVendors] = useState()
  const [items, setItems] = useState()

  const getVendors = async () => {
    try {
      const res = await getvendorsApi()
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        setVendors(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getItems = async () => {
    try {
      const res = await getItemSuggestionApi()
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        setItems(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendors()
    getItems()
  }, [])

  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };
  
  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Filter</h5>
                </div>
                <form className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="number" >PO number</label>
                    <input type="text" className="form-control" id="number" placeholder="Enter number" name='number' value={formik?.values?.number} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />

                  </div>

                  <div className="ed-box">
                    <label htmlFor="vendorName" className="form-label">Vendor name</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.vendorName ? { label: formik?.values?.vendorName } : null}
                      placeholder="Select "
                      isSearchable={true}
                      name="state"
                      options={vendors}
                      onChange={(e) => {
                        formik.setFieldValue("vendorName", e.label)
                      }}
                    />
                  </div>

                  <div className="ed-box">
                    <label htmlFor="hsnCode" className="form-label">HSN code</label>
                    <input type="text" className="form-control" id="hsnCode" placeholder="Enter hsn" name='hsnCode' value={formik?.values?.hsnCode} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />

                  </div>
                  <div className="ed-box">
                    <label htmlFor="to" className="form-label">Date</label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className='form-control cursor-pointer'
                      startDate={ formik.values?.to ? formik.values?.to :null }
                      endDate={formik.values?.from ?formik.values?.from :null}
                      maxDate={new Date(new Date(formik.values?.to ? formik.values?.to : new Date()).setTime(new Date(formik.values?.to ? formik.values?.to : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                      onChange={(update) => {
                        handleSelectDate(update[0], "to")
                        handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='from - to'
                      showDisabledMonthNavigation
                    />
                    {/* <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className='form-select cursor-pointer'
                      startDate={formik.values?.to}
                      endDate={formik.values?.from}
                      maxDate={formik.values?.to ? moment(formik.values?.to).tz('America/New_York').add(180, 'days').toDate():null} // Eastern Time Zone (USA)
                      onChange={(update) => {
                        handleSelectDate(moment(update[0]).tz('America/New_York').toDate(), "to")
                        handleSelectDate(moment(update[1]).tz('America/New_York').toDate(), "from")
                        // handleSelectDate(update[0], "to")
                        // handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='date'
                      showDisabledMonthNavigation
                    /> */}
                  </div>

                  <div className="ed-box">
                    <label htmlFor="Item" className="form-label">Item</label>
                    {/* <input type="text" className="form-control" id="Item" placeholder="Enter item" name='Item' value={formik?.values?.Item} onChange={formik?.handleChange} onBlur={formik?.handleBlur} /> */}
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.Item ? { label: formik?.values?.Item } : null}
                      placeholder="Select "
                      isSearchable={true}
                      name="Item"
                      options={items}
                      onChange={(e) => {
                        formik.setFieldValue("Item", e.label)
                        formik.setFieldValue("itemId", e.value?.name || "")
                      }}
                    />
                  </div>

                  <div className="ed-box">
                    <label htmlFor="status" className="form-label">Status</label>
                    <select className="form-select" name='status' aria-label="Default select example" value={formik?.values?.status} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">Select</option>
                      <option value="created">Created</option>
                      <option value="submitted">Submitted</option>
                      <option value="approved">Approved</option>
                    </select>
                  </div>

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <div className="save" onClick={(e) => { clickHandler(); document.getElementById(modalId).click() }}>
                      Apply
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter