import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, convertToPurchaseApi, getPurchaseOrderByIdApi, makeCopyApi,downloadPOPdf } from '../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';

function ViewPurchaseOrder() {
  const params = useParams();
  const { userDetails } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [purchaseOrder, setPurchaseOrder] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const confirmMakeAcopy = "confirmMakeAcopy";
  const convertToPurchaseModalId = "convertToPurchaseModalId"
  const [taxItems, setTaxItems] = useState({});
  const [loader, setLoader] = useState({
    approve: false,
    makeAcopy: false,
    convertToPurchase: false
  })
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const location = useLocation();
  
  const previewTaxItems = (items) => {
    let taxItem = {};
    items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }

  const getPurchaseOrderById = async (id) => {
    try{
      const res = await getPurchaseOrderByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setPurchaseOrder(podata);
        previewTaxItems(podata?.items)
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * Number(item?.quantity)
      })
      return price
    }
    return 0;
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }

  const handlePoApproval = async () => {
    try {
      const res = await approvePurchaseOrderApi({ id: id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(`Purchase Order ${purchaseOrder?.poNumber || ""} is successfully approved.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getPurchaseOrderById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const makeAcopyHandler = async () => {
    try {
      const res = await makeCopyApi({ id: purchaseOrder._id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmMakeAcopy).click()
        setConfirmationMsg(`Purchase Order ${res.data?.data?.purchaseOrder[0]?.poNumber || ""} is successfully created.`)
        document.getElementById("statusModalBtn").click()
        if (res.data?.data?.purchaseOrder?.[0]?._id) {
          setNavigationRoute(`/purchase/update/${res.data?.data?.purchaseOrder?.[0]?._id}`)
        } else {
          setNavigationRoute("/purchase")
        }

      }
    } catch (error) {
      toast.error("something went wrong");
    }
  }



  const convertTopurchase = async () => {

    setLoader({ ...loader, convertToPurchase: true })
    try {
      const res = await convertToPurchaseApi({ purchaseOrderId: id })
      if (res.status === 200) {
        toast.success(res.data?.message);
        setConfirmationMsg(`Purchase Order ${purchaseOrder?.poNumber || ""} is successfully converted to purchase.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute(`/challan/Edit/${res.data?.data?._id}`);
      }
    } catch (error) {
      if(error && error?.response?.status === 409 && error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
      }else{
        toast.error("something went wrong");
      }
    }
    setLoader({ ...loader, convertToPurchase: false })
  }

  const addressFormatter = (vendor) => {
    let address = ""

    addressKey.forEach((ele, index) => {
      if (vendor?.[ele]) {
        address += vendor?.[ele] + ((addressKey.length - 1) === index ? "" : ", ");
      }
    });
    return address
  }

  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getPurchaseOrderById(id)
    }
  }, [])

  const getDownloadPO =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadPOPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', purchaseOrder?.poNumber ? `${purchaseOrder.poNumber}.pdf` :'po.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }


  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <h1 className='text-capitalize'>
                        {purchaseOrder?.vendor?.name ? purchaseOrder?.vendor?.name : ""}

                      </h1>
                    </div>
                   
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">PO Number</div>
                    <div>{purchaseOrder?.poNumber}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Created PO date</div>
                    <div>{moment(purchaseOrder?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">GST Number</div>
                    <div>{purchaseOrder?.vendor?.gstNumber}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">PO due date</div>
                    <div>{moment(purchaseOrder?.dueDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Status</div>
                    <div className='text-capitalize'>{purchaseOrder?.status}</div>
                  </div>
                  <hr />
                  <div className='fs-3 fw-normal mt-2'>Address:</div>
                  <div className='mt-3'>{addressFormatter(purchaseOrder?.vendor?.vendorAddressId)}</div>
                  <hr />
                  <div className='fs-3 fw-normal'>
                    Site details
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site name</div>
                    <div>{purchaseOrder?.site?.[0]?.name}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site address</div>
                    <div>{purchaseOrder?.site?.[0]?.addressId?.address1}</div>
                  </div>
                  <hr />
                  <h3>
                    Items
                  </h3>
                  <div className='table-responsive'>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">ITEMS NAME</th>
                          {/* <th scope="col">HSN CODE</th> */}
                          <th scope="col">QTY</th>
                          <th scope="col">UNIT RATE</th>
                          <th scope="col">GST</th>
                          <th scope="col">AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody >
                        {purchaseOrder?.items?.map((ele, index) => {
                          
                          return (
                            <tr key={index}>
                              <th>{ele?.name ? ele?.name : ele?.itemDetails?.name}</th>
                              {/* <td>{ele?.hsnCode ? ele?.hsnCode : ele?.itemDetails?.hsnCode}</td> */}
                              <td>{ele?.quantity}/{ele?.unitType ? ele?.unitType : ele?.itemDetails?.unitType}</td>
                              <td>{ele?.unitPrice}</td>
                              <td>{ele?.tax ? ele?.tax : ele?.itemDetails?.tax}%</td>
                              <td>{formatPrice(ele?.quantity * ele?.unitPrice)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>

                    <div className='amount-box p-2 px-3'>
                      <div className="d-flex justify-content-between gap-2 mt-3">
                        <div className="fw-bold">Amount</div>
                        <div>{formatPrice(totalPrice(purchaseOrder?.items))}</div>
                      </div>
                      {(Object.keys(taxItems).sort((a, b) => b - a)).map((item, index) => {
                        
                        return (
                          <div key={index}>
                            <div className="d-flex justify-content-between gap-2 mt-3">
                              <div className="fw-bold">CGST ({item / 2}% on {totalPrice(taxItems[item])?.toFixed(2)}) </div>
                              <div>{formatPrice((totalPrice(taxItems[item]) * item / 2) / 100)}</div>
                            </div>
                            <div className="d-flex justify-content-between gap-2 mt-3">
                              <div className="fw-bold">SGST ({item / 2}% on {totalPrice(taxItems[item])?.toFixed(2)}) </div>
                              <div>{formatPrice((totalPrice(taxItems[item]) * item / 2) / 100)}</div>
                            </div>
                          </div>
                        )
                      })}

                      <hr />
                      <div className="d-flex total justify-content-between align-items-center">
                      <p>R/off</p>
                      <p>{getRoundOff(totalAmount(purchaseOrder?.items)).toFixed(2)}</p>
                    </div>
                    <div className="d-flex total justify-content-between align-items-center">
                      <p>Total amount</p>
                      <p>{formatPrice(Math.round(totalAmount(purchaseOrder?.items)))}</p>
                    </div>
                      {/* <div className="d-flex justify-content-between gap-2 mt-3">
                        <div className="fw-bold">Total amount</div>
                        <div>{formatPrice(totalAmount(purchaseOrder?.items))}</div>
                      </div> */}
                    </div>
                  </div>
                  <hr />           
                  {purchaseOrder?.remarks && <>
                    <div className='fs-3 fw-normal'>
                      Remarks:
                    </div>
                    <div className=''>{purchaseOrder?.remarks}</div>
                    <hr />
                    </>}

                  {purchaseOrder?.termsAndCondition?.title && <>
                  <div className='fs-3 fw-normal'>
                    Terms and conditions
                  </div>
                  <div className="">
                    <div className="fw-bold">{purchaseOrder?.termsAndCondition?.title} </div>
                    <div>{purchaseOrder?.termsAndCondition?.description}</div> 
                  </div>
                  <hr />
                  </>}
                    
                  <div className="d-flex justify-contend-start gap-2">
                    {purchaseOrder?.status === "submitted" && userDetails?.roleId?.permission.includes("poApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`}>Approve</div>
                    </div>}

                    {userDetails?.roleId?.permission.includes("poWrite") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-secondary" data-bs-toggle="modal" data-bs-target={`#${confirmMakeAcopy}`}>Make a copy</div>
                    </div>}

                    {Boolean(purchaseOrder?.showConvertToPurchaseBtn) ? userDetails?.roleId?.permission.includes("poConvertToPurchase") && <div className="d-flex justify-content-between gap-2 mt-3" >
                      <button className="btn btn-dark" data-bs-toggle="modal" data-bs-target={`#${convertToPurchaseModalId}`}>Convert to purchase</button>
                    </div>
                      : <div className="d-flex justify-content-between gap-2 mt-3" >
                        <button className="btn btn-dark" disabled={!Boolean(purchaseOrder?.showConvertToPurchaseBtn)}  >Challan created</button>
                      </div>}

                    {userDetails?.roleId?.permission.includes("poUpdate") && purchaseOrder?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/purchase/update/${id}`) }} > Edit</button></div>}

                    {userDetails?.roleId?.permission.includes("voucherWrite") && purchaseOrder?.status?.toLowerCase() !== "created" && <div className="d-flex justify-content-between gap-2 mt-3"><button className="create-order" onClick={() => navigate("/voucher/add", { state: { number: purchaseOrder?.poNumber,name:purchaseOrder?.vendor?.name, amount: purchaseOrder.totalRemainingVoucherAmount ??  totalAmount(purchaseOrder?.items) } })}>Create voucher</button></div>}
                    <div className="d-flex justify-contend-start gap-2">
                   {purchaseOrder?.status?.toLowerCase() === "approved" && <div className="d-flex justify-content-between gap-2 mt-3">
                      <button className="btn create-order text-white" disabled={download} onClick={()=>!download && id && getDownloadPO(id)}>Download</button>
                    </div>}
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handlePoApproval} message={<>Are you sure you want to <br /> Approve ?</>} />
      <ConfirmationModal modalId={confirmMakeAcopy} handleConfirm={makeAcopyHandler} message={<>Are you sure you want to <br /> Make a Copy ?</>} />
      <ConfirmationModal modalId={convertToPurchaseModalId} handleConfirm={convertTopurchase} message={<>Are you sure you want to convert <br /> to  purchase ?</>}/>
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewPurchaseOrder