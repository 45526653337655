
export const  getToken = () => localStorage.getItem("token")

export const removeToken = () => localStorage.removeItem("token")
export const isAuth = () => Boolean(getToken())

export const capitalizeWords=(str)=> {
    if(!str) return
    const words = str?.split?.(' ');
  
    const capitalizedWords = words?.map(word => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word; 
      }
    });
  
    return capitalizedWords?.join(' ');
  }

  export const openImageInNewTab = (image) => {
    const imageUrl = `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${image}`
    window.open(imageUrl, '_blank');
  };

  export const formatPrice = (amount) =>{
    let newAmount = amount || 0
    return "₹ " + addCommas(newAmount.toFixed(2))
  }

function addCommas(number) {
  let numberStr = String(number);
  let parts = numberStr.split('.');
  let integerPart = parts[0];
  let result = '';
  for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
    if (count !== 0 && count % 3 === 0) {
      result = ',' + result;
    }
    result = integerPart[i] + result;
  }
  if (parts.length > 1) {
    result += '.' + parts[1];
  }
  return result;
}

export const convertByteToMB = (byte) => {
  if(!byte) return 0;
  return byte / (1024 * 1024)
}

export const acceptAlphaAndNumOnly = /^[a-zA-Z0-9]+$/;

export const getFileOriginalName = (filename) => {
 return filename?.split('_').slice(1)?.join('');
}

export const getRoundOff =(value)=>{
  if(isNaN(value)){
    return 0
  }else{
    return Number.parseFloat(Number(Math.round(value)-value).toFixed(2))
  }
}

export const validatePONumber = /PO-\d+/
export const validateChallanNumber = /CH-\d+/
export const validateVoucherNumber = /VR-\d+/
export const validatePOOrChallanNumber = /(PO|CH)-\d+/
export const validatePOChallanOrVoucherNumber = /(PO|CH|VR)-\d+/

